var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"image",staticClass:"text-options-wrapper",class:{ 'format-block': !_vm.isFormat }},[(!_vm.isFormat)?_c('div',{staticClass:"flex-wrapper"},[_c('h3',{staticClass:"format-block-title"},[_vm._v(" "+_vm._s(_vm.isStory ? _vm.$t("create.step3.textStory") : _vm.$t("create.step3.buttonSelector.thumbnailText"))+" ")])]):_vm._e(),_c('div',{staticClass:"button-inputs",class:{ 'bulk-button-inputs': _vm.isBulk }},[(!_vm.isFormat)?_c('div',{staticClass:"text-form"},[_c('label',[_vm._v(_vm._s(_vm.$t("create.step3.text")))]),_c('input',{staticClass:"text-input --with-borders smallInput",attrs:{"maxlength":_vm.isStory ? 20 : 1000,"type":"text"},domProps:{"value":_vm.textOptions.text || _vm.currentVideo.videoTitle},on:{"input":function($event){return _vm.onTextChange({
            inputFor: 'text',
            value: $event.target.value,
          })}}})]):_vm._e(),(_vm.isFormat)?_c('div',{staticClass:"styles-form"},[_c('label',[_vm._v(_vm._s(_vm.$t("create.step3.buttonSelector.textStyle")))]),_c('div',{staticClass:"style-inputs",class:{ 'bulk-style-inputs': _vm.isBulk }},[_c('div',{staticClass:"text-styles"},[_c('button',{staticClass:"button-input",class:{ selected: _vm.computedTextStyles.isBold },on:{"click":function($event){return _vm.toggleButton(`isBold`)}}},[_c('Bold')],1),_c('button',{staticClass:"button-input",class:{ selected: _vm.computedTextStyles.isItalic },on:{"click":function($event){return _vm.toggleButton(`isItalic`)}}},[_c('Italic')],1),_c('button',{staticClass:"button-input",class:{ selected: _vm.computedTextStyles.isUnderlined },on:{"click":function($event){return _vm.toggleButton(`isUnderlined`)}}},[_c('Underlined')],1)]),(!_vm.isStory)?_c('div',{staticClass:"position-wrapper"},[_c('div',{staticClass:"position-styles",class:{ 'bulk-flex': _vm.isBulk }},[_c('button',{staticClass:"button-input",class:{ selected: _vm.computedTextStyles.position === 'end' },on:{"click":function($event){return _vm.onTextOptionsChange({
                  inputFor: 'position',
                  value: 'end',
                })}}},[_c('PositionBottom')],1),_c('button',{staticClass:"button-input",class:{ selected: _vm.computedTextStyles.position !== 'end' },on:{"click":function($event){return _vm.onTextOptionsChange({
                  inputFor: 'position',
                  value: 'start',
                })}}},[_c('PositionTop')],1)]),_c('button',{staticClass:"button-input wider",on:{"click":function($event){_vm.showModal = true}}},[_c('Left'),_c('svg',{staticClass:"accordion-icon",class:{ isOpen: _vm.showModal },attrs:{"fill":"none","stroke":"currentColor","viewBox":"0 0 16 10","aria-hidden":"true"}},[_c('path',{attrs:{"d":"M15 1.2l-7 7-7-7","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])],1)]):_vm._e(),(_vm.showModal)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hideModal),expression:"hideModal"}],staticClass:"align-modal",class:{ 'bulk-align-modal': _vm.isBulk }},[_c('div',{staticClass:"align-button",on:{"click":function($event){return _vm.onTextOptionsChange({
                inputFor: 'placement',
                value: 'left',
              })}}},[_c('button',{staticClass:"button-input",class:{
                selected:
                  !_vm.computedTextStyles.placement ||
                  _vm.computedTextStyles.placement == 'left',
              }},[_c('Left')],1),_vm._v(" "+_vm._s(_vm.$t("shared.toggleBox.left"))+" ")]),_c('div',{staticClass:"align-button",on:{"click":function($event){return _vm.onTextOptionsChange({
                inputFor: 'placement',
                value: 'center',
              })}}},[_c('button',{staticClass:"button-input",class:{ selected: _vm.computedTextStyles.placement == 'center' }},[_c('Center')],1),_vm._v(" "+_vm._s(_vm.$t("shared.toggleBox.center"))+" ")]),_c('div',{staticClass:"align-button",on:{"click":function($event){return _vm.onTextOptionsChange({
                inputFor: 'placement',
                value: 'right',
              })}}},[_c('button',{staticClass:"button-input",class:{ selected: _vm.computedTextStyles.placement == 'right' }},[_c('Right')],1),_vm._v(" "+_vm._s(_vm.$t("shared.toggleBox.right"))+" ")])]):_vm._e()])]):_vm._e(),(_vm.isFormat)?_c('div',{staticClass:"input-color"},[(!_vm.isStory)?_c('label',{staticClass:"box-title"},[_vm._v(_vm._s(_vm.$t("create.step3.buttonSelector.textColorAndSize")))]):_c('label',{staticClass:"box-title"},[_vm._v(" "+_vm._s(_vm.$t("create.step3.buttonSelector.textColor")))]),_c('div',{staticClass:"buttons-color"},[(!_vm.isStory)?_c('div',{staticClass:"size-button"},[_c('input',{staticClass:"size-input",attrs:{"type":"number","min":"12","max":"30","content":"size"},domProps:{"value":_vm.computedTextStyles.size},on:{"focusout":_vm.validateCarouselInputSize,"input":function($event){return _vm.onTextOptionsChange({
                inputFor: 'size',
                value: $event.target.value,
              })}}}),_c('span',[_vm._v("px "),_c('TooltipIcon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                placement: 'right',
                a11y: false,
                boundary: 'window',
              }),expression:"{\n                arrow: true,\n                placement: 'right',\n                a11y: false,\n                boundary: 'window',\n              }"}],staticClass:"tooltip-icon",attrs:{"content":_vm.$t('create.step3.buttonSelector.carouselTooltip')}})],1)]):_c('div',{staticClass:"size-button"},[_c('input',{staticClass:"size-input",attrs:{"type":"number","min":"10","max":_vm.maximumSizeForStories,"content":"size"},domProps:{"value":_vm.computedTextStyles.size},on:{"focusout":_vm.validateStoriesInputSize,"input":function($event){return _vm.onTextOptionsChange({
                inputFor: 'size',
                value: $event.target.value,
              })}}}),_c('span',[_vm._v("px "),_c('TooltipIcon',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                arrow: true,
                placement: 'right',
                a11y: false,
                boundary: 'window',
              }),expression:"{\n                arrow: true,\n                placement: 'right',\n                a11y: false,\n                boundary: 'window',\n              }"}],staticClass:"tooltip-icon",attrs:{"content":_vm.$t('create.step3.buttonSelector.storiesTooltip')}})],1)]),_c('button',{staticClass:"value-color",style:(`background-color:${_vm.computedTextStyles.color}`),on:{"click":function($event){return _vm.showPicker(`color`)}}})])]):_vm._e(),(!_vm.isStory && _vm.isFormat)?_c('div',{staticClass:"input-color"},[_c('label',{staticClass:"box-title"},[_vm._v(_vm._s(_vm.$t("create.step3.buttonSelector.backgroundColor")))]),_c('div',{staticClass:"buttons-color"},[_c('button',{staticClass:"value-color",style:(`background-color:${_vm.computedTextStyles.backgroundColor}`),on:{"click":function($event){return _vm.showPicker(`backgroundColor`)}}})])]):_vm._e()]),(_vm.displayPicker)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hidePicker),expression:"hidePicker"}],key:"color-picker",staticClass:"vidjet-color-picker"},[_c('colorpicker',{attrs:{"value":_vm.formatTextOptions[_vm.pickerType] ||
        (_vm.pickerType == 'color' ? 'black' : 'FFFFFF5E')},on:{"input":function($event){return _vm.onPickerChange($event, _vm.pickerType)}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }