<template>
  <sliding-modal
    :title="videosTitle"
    :feed-name="sameCode"
    :display-close-button="false"
    @close="close"
  >
    <div class="embed-code-sliding-container">
      <h2 class="subtitle">
        {{ $t("create.bulk.embedModal.subtitle.text1") }}
        <span
          ><a
            href="https://help.vidjet.io/en/articles/8368680-embedding-the-master-component-on-your-page-template"
            target="_blank"
            >{{ $t("create.bulk.embedModal.subtitle.link") }}
          </a></span
        >
        {{ $t("create.bulk.embedModal.subtitle.text2") }}
      </h2>
      <div class="script-to-copy">
        <p class="iframe-code-to-copy">
          {{ getEmbedCode(campaign, siteIntegration) }}
        </p>
        <button
          v-clipboard="
            () => getEmbedCode(campaign, siteIntegration).replace(/(\r\n|\n|\r)/gm, '')
          "
          v-clipboard:success="clipboardSuccessHandler"
          v-clipboard:error="clipboardErrorHandler"
          class="action-button"
        >
          {{ $t("create.step5.copyCode") }}
        </button>
      </div>
    </div>
    <div class="absolute-position">
      <button class="cancel-button flex-button" @click="close">
        {{ $t("create.bulk.modalButtons.leave") }}
        <SmallSquaredX class="button-icon" />
      </button>
      <button class="save-button flex-button" @click="close">
        {{ $t("create.bulk.modalButtons.confirm")
        }}<SquaredCheckIcon class="button-icon" />
      </button>
    </div>
  </sliding-modal>
</template>

<script>
import SlidingModal from "@/components/shared/SlidingModal.vue";
import getEmbedCode from "@/utils/get-embed-code.js";
import SquaredCheckIcon from "@/assets/svg/squared-check-icon.svg?inline";
import SmallSquaredX from "@/assets/svg/small-squared-x-icon.svg?inline";
import { mapGetters } from "vuex";


export default {
  components: {
    SlidingModal,
    SquaredCheckIcon,
    SmallSquaredX,
  },
  props: {
    campaign: { type: Object, default: null },
  },
  computed: {
    ...mapGetters({
      siteIntegration: "site/siteIntegration"
    }),
  },
  data() {
    return {
      videosTitle: this.$t("create.bulk.embedModal.title"),
      sameCode: this.$t("create.bulk.embedModal.allFeeds"),
      getEmbedCode,
    };
  },
  methods: {
    close() {
      this.$emit("close-embed-code-modal");
    },
    clipboardSuccessHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.copied"),
        type: "success",
      });
    },
    clipboardErrorHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.failedToCopy"),
        type: "error",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.embed-code-sliding-container {
  position: relative;
  height: 100%;
}
.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  text-align: center;
  width: 70%;
  margin: 0 auto 24px;
}

.script-to-copy {
  position: relative;
  border: 1px solid $dark-purple;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: $card-border-radius;
  margin: 0 auto;
  overflow: auto;
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 24px;
  place-items: center;
  width: 80%;
  background-color: white;
  @include font-small;
}

.buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 74px;
  position: absolute;
  bottom: 80px;
  left: 0;
  height: 80px;
  margin-right: 52px;
  background-color: white;
  filter: drop-shadow(0px -2px 8px rgba(0, 0, 0, 0.15));
}

.flex-button {
  display: flex;
  align-items: center;
  gap: 8px;
}
.absolute-position {
  position: absolute;
  right: 16px;
  top: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}
</style>
