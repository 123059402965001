<template>
  <div class="stories-shape-settings">
    <div class="stories-shape-wrapper">
      <h3 class="title">
        {{ titleText }}
      </h3>
      <div
        class="stories-shape-container arrow-icon-box"
        :class="{
          'bulk-icon-type-boxes': isBulk,
        }"
      >
        <button
          v-for="shape in computedShapes"
          :key="shape.name"
          class="icon-type"
          :class="{
            selected: storiesShape === shape.name,
          }"
          @click="
            onShapeChange({
              inputFor: isBubble ? 'bubbleShape' : 'storiesShape',
              value: shape.name,
            })
          "
        >
          <component :is="shape.icon"></component>
          <h3>{{ shape.label | capitalize }}</h3>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},

  props: {
    campaignFormatOptions: {
      type: Object,
      default: () => {
        return {};
      },
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
    isBubble: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      shapes: [
        {
          name: "round",
          label: "round",
          icon: require("@/assets/svg/stories-shapes/round-shape.svg?inline"),
        },
        {
          name: "semi",
          label: "semi",
          icon: require("@/assets/svg/stories-shapes/semi-shape.svg?inline"),
        },
        {
          name: "square",
          label: "square",
          icon: require("@/assets/svg/stories-shapes/square-shape.svg?inline"),
        },
        {
          name: "portrait",
          label: "portrait",
          icon: require("@/assets/svg/stories-shapes/portrait-shape.svg?inline"),
        },
      ],
      bubbleShapes: [
        {
          name: "round",
          label: "round",
          icon: require("@/assets/svg/stories-shapes/round-shape.svg?inline"),
        },
        {
          name: "square",
          label: "square",
          icon: require("@/assets/svg/stories-shapes/square-shape.svg?inline"),
        },
        {
          name: "original",
          label: "original",
          icon: require("@/assets/svg/stories-shapes/portrait-shape.svg?inline"),
        },
      ],
    };
  },

  computed: {
    formatObject() {
      return this.campaignFormatOptions;
    },
    storiesShape() {
      return this.isBubble
        ? this.formatObject.bubbleShape || "round"
        : this.formatObject.storiesShape || "round";
    },
    computedShapes() {
      if (this.isBubble) {
        return this.bubbleShapes;
      } else return this.shapes;
    },
    titleText() {
      return this.isBubble
        ? this.$t("shared.toggleBox.bubbleShape")
        : this.$t("create.step3.storiesShapes.title");
    },
  },
  methods: {
    onShapeChange(e) {
      this.$emit("change-format-object", {
        inputFor: e.inputFor,
        value: e.value,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.stories-shape-wrapper:hover {
  background-color: $settings-hover;
}
.stories-shape-settings {
  display: flex;
  flex-direction: column;
  gap: 12px;
  .title {
    display: flex;
    justify-content: space-between;
    display: flex;
    font-weight: 400;
    margin-bottom: 12px;
    .right {
      transform: rotate(180deg);
    }
  }

  .bulk-icon-type-boxes {
    min-width: unset;
    max-width: 280px;
    gap: 8px;
    @media (max-width: 1350px) {
      max-width: 250px;
    }
    @media (max-width: 1250px) {
      max-width: 230px;
    }
  }
}
.stories-shape-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}
.icon-type.selected svg rect {
  fill: $dark-purple;
  stroke: $dark-purple;
}
</style>
