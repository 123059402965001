var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stories-shape-settings"},[_c('div',{staticClass:"stories-shape-wrapper"},[_c('h3',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.titleText)+" ")]),_c('div',{staticClass:"stories-shape-container arrow-icon-box",class:{
        'bulk-icon-type-boxes': _vm.isBulk,
      }},_vm._l((_vm.computedShapes),function(shape){return _c('button',{key:shape.name,staticClass:"icon-type",class:{
          selected: _vm.storiesShape === shape.name,
        },on:{"click":function($event){return _vm.onShapeChange({
            inputFor: _vm.isBubble ? 'bubbleShape' : 'storiesShape',
            value: shape.name,
          })}}},[_c(shape.icon,{tag:"component"}),_c('h3',[_vm._v(_vm._s(_vm._f("capitalize")(shape.label)))])],1)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }