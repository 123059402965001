<template>
  <div
    v-if="!hideText"
    class="text-container"
    :class="`${textPosition}`"
    :style="{
      'text-align': `${textOptions.placement}`,
      'border-radius': `${borderRadius}`,
      background: `${background}`,
    }"
  >
    <h3
      class="player-text"
      :style="{
        color: `${textOptions.color}`,
        'font-size': `${textOptions.size}px`,
        'line-height': `${textOptions.size}px`,
        'font-weight': `${isBold}`,
        'font-style': `${isItalic}`,
        'text-decoration': `${isUnderlined}`,
      }"
    >
      {{ decodeTextPlayer(textInputted) }}
    </h3>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { decodeText } from "../../utils/utils";
export default {
  props: {
    videoIndex: {
      type: Number,
      default: 0,
    },
    isQuickShop: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters("videos", ["getVideoByIndex"]),
    ...mapGetters("player", ["isMobile"]),
    ...mapGetters("playerCampaign", ["format", "campaign"]),

    hideText() {
      return this.isMobile && this.format?.carouselThumbnailAmount === 2;
    },

    textOptions() {
      const textOptions =
        this.format?.textOptions &&
        Object.keys(this.format.textOptions).length > 0
          ? this.format.textOptions
          : this.campaign.bulk?.[0]?.videos?.[0]?.textOptions ||
            this.campaign.videos?.[0]?.textOptions;

      return textOptions;
    },
    textInputted() {
      const video = this.getVideoByIndex(this.videoIndex);
      if (video?.textOptions?.text) {
        return video.textOptions.text;
      } else return video.videoTitle;
    },
    background() {
      if (this.textOptions?.position === "end" && !this.isQuickShop)
        return `linear-gradient(180deg, rgba(255, 0, 0, 0.00) 0%, ${this.textOptions.backgroundColor} 100%)`;
      return `linear-gradient(180deg, ${this.textOptions.backgroundColor} 0%, rgba(255, 0, 0, 0.00) 100%)`;
    },
    isBold() {
      if (this.textOptions.isBold) {
        return "600";
      }
      return "500";
    },
    isItalic() {
      if (this.textOptions.isItalic) {
        return "italic";
      }
      return "normal";
    },
    isUnderlined() {
      if (this.textOptions.isUnderlined) {
        return "underline";
      }
      return "none";
    },
    borderRadius() {
      const radius = this.format.cornerShape;
      if (this.textOptions?.position === "end" && !this.isQuickShop) {
        return `0 0 ${radius}px ${radius}px`;
      }
      return `${radius}px ${radius}px 0 0`;
    },
    textPosition() {
      if (this.isQuickShop) {
        return "start";
      } else return this.textOptions?.position;
    },
  },

  methods: {
    decodeTextPlayer(string) {
      return decodeText(string);
    },
  },
};
</script>

<style lang="scss" scoped>
.start {
  top: 0;
}
.end {
  bottom: 0;
}
.text-container {
  position: absolute;
  z-index: 2;
  pointer-events: none;
  touch-action: none;
  width: 100%;
}

.player-text {
  padding: 16px;
  font-size: 16px;
  overflow: hidden;
}

@include media("<=phone") {
  .player-text {
    font-size: 14px;
  }
}
</style>
