<template>
  <sliding-modal
    :is-widget-creator="true"
    :is-widget-creator-third-step="currentStep === 3"
    @close="closeWidgetCreator"
  >
    <div
      class="header-wrapper"
      :class="{ 'header-wrapper-third-step': currentStep === 3 }"
    >
      <div class="header">
        <h1 v-if="currentStep !== 3" class="title">
          {{ currentTitle }}
        </h1>
        <div class="buttons">
          <button class="step-button back" @click="prevStep">
            <ArrowNext class="arrow-back" />
            <p>{{ $t("shared.buttons.back") }}</p>
          </button>
          <button
            class="step-button next"
            :class="{ 'disabled-button': isVideoLibraryOpen }"
            :disabled="isVideoLibraryOpen"
            @click="nextStep"
          >
            <p>
              {{
                currentStep === maxSteps
                  ? $t("shared.buttons.save")
                  : $t("shared.buttons.Next")
              }}
            </p>
            <component
              :is="currentStep === maxSteps ? 'CircleChecked' : 'ArrowNext'"
              class="button-icon"
            />
          </button>
        </div>
      </div>
      <div class="header">
        <h2 v-if="currentStep !== 3" class="subtitle">
          {{ currentSubtitle }}
        </h2>
        <p class="steps">
          {{ $t("widgetCreator.step") }} {{ currentStep }}/{{ maxSteps }}
        </p>
      </div>
    </div>
    <WidgetCreatorFirstStep
      v-if="currentStep === 1"
      @card-selected="handleFirstStepSelection"
    />
    <WidgetCreatorSecondStep
      v-else-if="currentStep === 2"
      @card-selected="handleSecondStepSelection"
    />
    <Step3
      v-else-if="currentStep === 3"
      :is-widget-creator="true"
      @update-video-library-status="updateVideoLibraryStatus"
    />
    <Step4
      v-else-if="currentStep === 4"
      :is-widget-creator="true"
      :is-home-page="isHomePage"
      :home-page-web-site="websiteUrl"
      @update-page-segmentation="updatePageSegmentation"
      @update-advanced-segmentation="updateAdvancedSegmentation"
    />
    <ExitWidgetCreation
      v-if="showLeaveModal"
      @close="showLeaveModal = false"
      @leave="$emit('close')"
    />
  </sliding-modal>
</template>

<script>
import { tagMixin } from "@/utils/add-bulk-tag.js";
import { mapGetters, mapState } from "vuex";
import enums from "../../enums";
const { FormatType, DeviceType, StateFlag } = enums;

import SlidingModal from "@/components/shared/SlidingModal.vue";
import WidgetCreatorFirstStep from "@/components/create-campaign-steps/WidgetCreatorFirstStep.vue";
import WidgetCreatorSecondStep from "@/components/create-campaign-steps/WidgetCreatorSecondStep.vue";
import Step3 from "@/components/create-campaign-steps/Step3.vue";
import Step4 from "@/components/create-campaign-steps/Step4.vue";
import ExitWidgetCreation from "@/components/create-campaign-steps/ExitWidgetCreation.vue";

import ArrowNext from "@/assets/svg/arrow-next.svg?inline";
import CircleChecked from "@/assets/svg/circle-checked-white.svg?inline";
export default {
  name: "WidgetCreatorSlidingModal",
  components: {
    SlidingModal,
    ArrowNext,
    WidgetCreatorFirstStep,
    WidgetCreatorSecondStep,
    Step3,
    Step4,
    CircleChecked,
    ExitWidgetCreation,
  },
  mixins: [tagMixin],
  props: {
    comesFromProductPages: {
      type: Boolean,
      default: false,
    },
    productIdSelected: {
      type: [String, Number],
      default: null,
    },
    productNameSelected: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      currentStep: 1,
      maxSteps: 2,
      isBulk: false,
      isHomePage: false,
      isCodeFormat: false,
      isCardSelected: false,
      selectedFormat: null,
      campaign: null,
      name: {
        en: "Regular Widget",
        fr: "Widget Simple",
      },
      selectedFormatObject: null,
      showLeaveModal: false,
      formattedSegmentation: {
        trigger: {
          type: 0,
        },
        viewRestriction: {
          maxView: 10,
          notShownTime: 24,
        },
      },
      campaigns: [],
      newCampaignCreatedId: null,
      isVideoLibraryOpen: false,
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapState({
      campaignsState: ({ campaign }) => campaign.campaigns,
    }),
    ...mapState({
      campaignCreation: (state) => state.campaign.campaignCreation,
    }),
    ...mapState({
      products: (state) => state.product.products,
    }),
    ...mapGetters({
      siteId: "account/getSiteId",
      defaultStoryFormatObject: "campaign/defaultStoryFormatObject",
      defaultCarouselFormatObject: "campaign/defaultCarouselFormatObject",
      defaultFormatObject: "campaign/defaultFormatObject",
      defaultEmbedObject: "campaign/defaultEmbedFormatObject",
      defaultBubbleProperties: "campaign/defaultBubbleProperties",
      defaultQuickShopFormatObject: "campaign/defaultQuickShopFormatObject",
      getCampaignCreation: "campaign/campaignCreation",
    }),
    currentTitle() {
      if (this.currentStep === 1) {
        return this.$t("widgetCreator.step1Title");
      } else if (this.currentStep === 2) {
        return this.$t("widgetCreator.step2Title");
      } else return this.$t("widgetCreator.step4Title");
    },
    currentSubtitle() {
      if (this.currentStep === 1) {
        return this.$t("widgetCreator.step1Subtitle");
      } else if (this.currentStep === 2) {
        if (this.isBulk) {
          return this.$t("widgetCreator.step2Subtitle1");
        } else if (this.isHomePage) {
          return this.$t("widgetCreator.step2Subtitle2");
        } else return this.$t("widgetCreator.step2Subtitle3");
      } else return this.$t("widgetCreator.step4Subtitle");
    },
    websiteUrl() {
      let url = this.site.site.websiteUrl;
      return url.endsWith("/") ? url : url + "/";
    },
  },
  async created() {
    if (this.comesFromProductPages) {
      this.isBulk = true;
      this.currentStep = 2;
    }
    this.campaigns = await structuredClone(this.campaignsState);
  },
  methods: {
    closeWidgetCreator() {
      if (this.currentStep >= 3) {
        this.showLeaveModal = true;
      } else {
        this.$emit("close");
      }
    },
    async nextStep() {
      if (this.currentStep < this.maxSteps) {
        if (this.currentStep === 1) {
          if (this.isCardSelected === false) {
            this.$notify({
              title: this.$t("shared.toastMessage.error"),
              text: this.$t("widgetCreator.notify.useCase"),
              type: "error",
            });
            return;
          }
          this.currentStep++;
        } else if (this.currentStep === 2) {
          if (this.selectedFormat === null) {
            this.$notify({
              title: this.$t("shared.toastMessage.error"),
              text: this.$t("widgetCreator.notify.format"),
              type: "error",
            });
            return;
          } else if (this.selectedFormat === "gallery") {
            return;
          }

          this.currentStep++;
        } else if (this.currentStep === 3) {
          if (this.campaignCreation.videos.length === 0) {
            this.$notify({
              title: this.$t("shared.toastMessage.error"),
              text: this.$t("widgetCreator.notify.video"),
              type: "error",
            });
            return;
          }
          this.currentStep++;
        }
      } else {
        if (this.currentStep === 2 && this.selectedFormat === "gallery") {
          return;
        }
        if (this.currentStep === 3) {
          if (this.campaignCreation.videos.length === 0) {
            this.$notify({
              title: this.$t("shared.toastMessage.error"),
              text: this.$t("widgetCreator.notify.video"),
              type: "error",
            });
            return;
          }
        }
        if (this.currentStep === 4) {
          this.$store.commit("campaign/campaignCreationSetSegmentation", {
            segmentation: this.formattedSegmentation,
          });
        }
        try {
          await this.createCampaignCall();
        } catch (error) {
          return;
        }
        this.$emit("close");
        this.$store.commit("campaign/resetCampaignState");
        if (this.isBulk && !this.$route.path.includes("product-pages")) {
          this.$router.push({
            name: "productPages",
          });
        }
        this.$store.dispatch("widgetCreator/displayWidgetCreatedModal", {
          isBulk: this.isBulk,
          isHomePage: this.isHomePage,
          isCodeFormat: this.isCodeFormat,
          showWidgetCreatedModal: true,
        });
      }
    },
    prevStep() {
      if (this.currentStep === 1) {
        this.$emit("close");
      } else if (this.currentStep === 2) {
        this.currentStep--;
        this.isCardSelected = false;
        this.selectedFormat = null;
      } else if (this.currentStep === 3) {
        this.currentStep--;
        this.selectedFormat = null;
        this.selectedFormatObject = null;
      } else if (this.currentStep === 4) {
        this.currentStep--;
        this.formattedSegmentation = {
          trigger: {
            type: 0,
          },
          viewRestriction: {
            maxView: 10,
            notShownTime: 24,
          },
        };
      }
    },
    handleFirstStepSelection(cardIndex) {
      this.isCardSelected = true;
      this.isBulk = cardIndex === 0;
      this.isHomePage = cardIndex === 1;

      if (cardIndex === 0) {
        this.maxSteps = 2;
      } else {
        this.maxSteps = 3;
      }
    },
    handleSecondStepSelection(format) {
      this.selectedFormat = format;
      this.selectedFormatObject = this.getFormatsObject(format);
      this.initCampaign();

      const codeFormats = [
        "quickShop",
        "stories",
        "carouselInline",
        "carouselFullscreen",
        "embed",
        "squaredStories",
        "portraitStories",
      ];
      if (codeFormats.includes(this.selectedFormat)) {
        this.isCodeFormat = true;
      } else {
        this.isCodeFormat = false;
      }
      if (!this.isBulk) {
        if (codeFormats.includes(this.selectedFormat)) {
          this.maxSteps = 3;
        } else {
          this.maxSteps = 4;
        }
      }
    },
    async createCampaignCall() {
      let campaign = {};
      try {
        campaign = await this.$store.dispatch("campaign/createCampaign");
      } catch (error) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: error,
          type: "error",
        });
        console.error("Failed to create campaign:", error);
        return;
      }
      this.newCampaignCreatedId = campaign.id;
      if (this.comesFromProductPages) {
        this.updateProductInStore();
      }
    },
    getFormatsObject(format) {
      switch (format) {
        case "quickShop":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultQuickShopFormatObject,
              isFullScreen: true,
              carouselThumbnailAmount: 1,
            },
          ];
        case "stories":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultStoryFormatObject,
              isHorizontalSwipe: false,
            },
          ];
        case "squaredStories":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultStoryFormatObject,
              isHorizontalSwipe: false,
              storiesShape: "square",
            },
          ];
        case "portraitStories":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultStoryFormatObject,
              isHorizontalSwipe: false,
              storiesShape: "portrait",
            },
          ];
        case "carouselInline":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultCarouselFormatObject,
              isFullScreen: false,
              isFullScreenMobile: false,
            },
          ];
        case "carouselFullscreen":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultCarouselFormatObject,
              isFullScreen: true,
              carouselThumbnailAmount: 1,
              isHorizontalSwipe: false,
            },
          ];
        case "bubble":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              deviceType: DeviceType.mobile,
            },
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              deviceType: DeviceType.desktop,
            },
          ];
        case "popup":
          return [
            {
              ...this.defaultFormatObject,
              formatType: FormatType.popup,
              deviceType: DeviceType.mobile,
              popupSize: "medium",
            },
            {
              ...this.defaultFormatObject,
              formatType: FormatType.popup,
              deviceType: DeviceType.desktop,
              popupSize: "medium",
            },
          ];
        case "embed":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultEmbedObject,
            },
          ];
      }
    },
    async initCampaign() {
      const tag = await this.addBulkTag();
      const isActive =
        this.selectedFormat === "bubble" || this.selectedFormat === "popup";
      let campaignData = {
        name: "Regular Widget",
        siteId: this.siteId,
        stateFlag: isActive ? StateFlag.active : StateFlag.paused,
        videos: [],
      };

      if (this.isBulk) {
        campaignData = {
          name: "Product Pages",
          tags: [tag._id],
          siteId: this.siteId,
          stateFlag: isActive ? StateFlag.active : StateFlag.paused,
          bulk: [],
        };
      }

      if (this.comesFromProductPages) {
        campaignData = {
          name: "Product Pages",
          tags: [tag._id],
          siteId: this.siteId,
          stateFlag: isActive ? StateFlag.active : StateFlag.paused,
          bulk: [
            {
              feedName: this.productNameSelected,
              segmentation: { productId: [this.productIdSelected] },
              videos: [],
            },
          ],
        };
      }

      this.$store.commit("campaign/campaignCreationInit", {
        dataToUpdate: campaignData,
      });
      this.$store.commit("campaign/campaignCreationSetFormat", {
        formats: this.selectedFormatObject,
      });
    },
    updatePageSegmentation(payload) {
      this.formattedSegmentation = payload;
      try {
        this.$store.commit("campaign/campaignCreationSetSegmentation", {
          segmentation: this.formattedSegmentation,
        });
      } catch (err) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: err,
          type: "error",
        });
      }
    },
    async updateProductInStore() {
      this.campaigns = await structuredClone(this.campaignsState);
      let campaign = this.campaigns[this.campaigns.length - 1];
      const getFormatName = (campaign) => {
        switch (campaign.formats[0].formatType) {
          case FormatType.bubble:
            return "bubble";
          case FormatType.popup:
            return "popup";
          case FormatType.embed:
            return "embed";
          case FormatType.carousel:
            return campaign.formats[0].isStory ? "story" : "carousel";
          default:
            return "";
        }
      };
      const product = this.getProductByProductKey();

      const updatedProduct = {
        ...product,
        _id: product._id,
        formatsAttached: [
          {
            campaignId: this.newCampaignCreatedId,
            campaignName: "Bulk embed",
            formatName: getFormatName(campaign),
            feedIndex: 0,
          },
        ],
      };

      this.$store.commit("product/updateProduct", updatedProduct);
    },
    getProductByProductKey() {
      const product = this.products.find(
        (product) => product.productId === this.productIdSelected
      );
      return product || null;
    },
    updateAdvancedSegmentation(payload) {
      this.formattedSegmentation = payload;
      try {
        this.$store.commit("campaign/campaignCreationSetSegmentation", {
          segmentation: this.formattedSegmentation,
        });
      } catch (err) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: err,
          type: "error",
        });
      }
    },
    updateVideoLibraryStatus(payload) {
      this.isVideoLibraryOpen = payload;
    },
  },
};
</script>

<style lang="scss" scoped>
.header-wrapper-third-step {
  position: absolute;
  right: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 100;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 24px 8px;
}

.title {
  @include base-font;
  @include font-big;
  font-weight: 600;
}

.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
}

.buttons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.step-button {
  @include base-font;
  @include font-small;
  font-weight: 600;
  width: 90px;
  height: 35px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.arrow-back {
  transform: rotate(180deg);
  path {
    fill: $light-grey;
  }
}

.back {
  border: 1px solid $light-grey;
  color: $light-grey;
  background-color: transparent;
  &:hover {
    border: 1px solid $dark-purple;
    background-color: $light-pink;
    color: $dark-purple;
    .arrow-back {
      path {
        fill: $dark-purple;
      }
    }
  }
}

.next {
  border: 1px solid $dark-purple;
  color: white;
  background-color: $dark-purple;
  &:hover {
    background-color: $light-pink;
    color: $dark-purple;
    .button-icon {
      path {
        fill: $dark-purple;
      }
    }
  }
}

.steps {
  @include base-font;
  @include font-small;
  font-weight: 400;
}

.disabled-button {
  border: 1px solid $light-grey;
  background: $light-grey;
  cursor: not-allowed;
  &:hover {
    background-color: $light-grey;
    color: white;
    .button-icon {
      path {
        fill: white;
      }
    }
  }
}
</style>
