import enums from "@/enums.js";
import { deepClone } from "../utils/utils";
const { DeviceType, FormatType, Position } = enums;
const state = {
  campaign: {},
  format: {},
  stopAllVideos: false,
};

const mutations = {
  SET_CAMPAIGN(state, campaign) {
    const clonedCampaign = deepClone(campaign);
    state.campaign = clonedCampaign;
  },

  SET_FORMAT(state, { format, device }) {
    if (
      format?.isFullScreenMobile &&
      device === "mobile"
    ) {
      format.isFullScreen = true;
      format.carouselThumbnailAmount = 1;
    }
    state.format = format;
  },

  SET_STOP_ALL_VIDEOS(state, value) {
    state.stopAllVideos = value;
  },
};

const getters = {
  campaign: (state) => state.campaign,
  format: (state) => state.format,
  isEmbed: (state) => state.format.formatType === FormatType.embed,
  isBubble: (state) => state.format.formatType === FormatType.bubble,
  isPopup: (state) => state.format.formatType === FormatType.popup,
  isCarousel: (state) => state.format.formatType === FormatType.carousel,
  isStory: (state) =>
    state.format.formatType === FormatType.carousel &&
    (state.format.isStory || false),
  isFullScreen: (state) => state.format.isFullScreen,
  isHorizontalSwipe: (state, _, rootState) => {
    return (
      (state.format.isHorizontalSwipe && !rootState.player.isMobile) ?? false
    );
  },
  isCarouselFullScreen: (state) =>
    state.format.isFullScreen &&
    state.format.formatType === FormatType.carousel,
  position: (state) =>
    state.format.position ??
    (state.format.formatType === FormatType.carousel //If it's carousel we want to default position to center while for bubble we want left
      ? Position.center
      : Position.left),
  isTrimmedVideoOnStart: () => {
    // autoplay = legacy property
    return (
      state.format.animatedThumbnail === "all" ||
      state.format.animatedThumbnail === true ||
      (state.format.formatType === FormatType.bubble &&
        state.format.openByDefault) ||
      state.format.formatType === FormatType.popup
    );
  },
  stopAllVideos: (state) => state.stopAllVideos,
};

const actions = {
  selectFormat({ state, commit }, device) {
    if (state.campaign.formats.length === 1) {
      commit("SET_FORMAT", { format: state.campaign.formats[0], device });
      return;
    }
    const format = state.campaign.formats.find(
      (format) => format.deviceType === DeviceType[device]
    );
    commit("SET_FORMAT", { format, device });
  },

  stopAllVideos({ commit }, value) {
    commit("SET_STOP_ALL_VIDEOS", value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
