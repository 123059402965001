<template>
  <div class="default-open-input">
    <div class="tooltip-div">
      <span class="open-text" :class="{ disabled: disabled }">{{
        labelText
      }}</span>
      <InterrogationMark
        v-if="hasTooltip"
        v-tippy="{
          placement: 'top',
          arrow: true,
          a11y: false,
          boundary: 'window',
        }"
        class="interrogation-mark"
        :content="$t('create.step3.quickShopTooltip')"
      />
      <SmallMobileIcon v-if="hasIcon" />
    </div>
    <slot />
    <vidjet-toggle-btn
      v-model="value"
      :checked="value"
      :text-after="labelText"
      :is-disabled="disabled"
    />
  </div>
</template>

<script>
import VidjetToggleBtn from "@/components/shared/Vidjet-toggle-btn.vue";
import InterrogationMark from "@/assets/svg/settings/interrogation-mark.svg?inline";
import SmallMobileIcon from "@/assets/svg/small-mobile-icon.svg?inline";
export default {
  components: {
    VidjetToggleBtn,
    InterrogationMark,
    SmallMobileIcon,
  },

  props: {
    inputFor: {
      type: String,
      default: "",
    },
    presetValue: {
      type: Boolean,
      default: true,
    },
    labelText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    hasTooltip: {
      type: Boolean,
      default: false,
    },
    hasIcon: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      value: true,
    };
  },

  watch: {
    value() {
      this.$emit("toggle-button", {
        inputFor: this.inputFor,
        value: this.value,
      });
    },
    presetValue() {
      this.value = this.presetValue;
    },
  },

  created() {
    this.value = this.presetValue;
  },
};
</script>

<style lang="scss" scoped>
.default-open-input {
  @include font-small;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  &:hover {
    background: $settings-hover;
  }
}

.open-text {
  align-self: center;
  text-align: left;
}
.disabled {
  color: $light-grey;
}

.tooltip-div {
  display: flex;
  align-items: center;
  gap: 4px;
}

.interrogation-mark {
  border: solid 1px #585858;
  border-radius: 50%;
  transform: scale(1.1);
}
</style>
