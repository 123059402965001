<template>
  <div
    class="bulk-embed-container"
    :class="{ 'bulk-embed-fixed': isSlidingModalOpen }"
  >
    <button class="close-icon" @click="goToCampaignManager(true)">
      <CloseIcon />
    </button>
    <div class="bulk-header">
      <h1 class="bulk-title">{{ $t("create.bulk.title") }}</h1>
      <div class="header-button">
        <div class="last-saved">
          {{ $t("create.bulk.lastSave.lastSaved") }} {{ lastSavedTime }}
        </div>
        <button
          v-if="!isPopup && !isBubble"
          class="bulk-action-button"
          @click="openEmbedCodeModal"
        >
          <CodeIcon />{{ $t("create.bulk.headerButtons.embedCode") }}
        </button>
        <button
          class="bulk-action-button"
          @click="openElementsSlidingModal(0, true)"
        >
          <PlayIcon />{{ $t("create.bulk.headerButtons.settings") }}
        </button>
        <button class="bulk-action-button create-feed" @click="createFeed">
          <PlusIcon />
          {{ $t("create.bulk.createFeed") }}
        </button>
      </div>
    </div>
    <div class="feed-nav">
      <div class="nav-buttons">
        <button
          class="feed-nav-button"
          :class="{ active: activeFeedType === 'general' }"
          @click="setActiveFeedType('general')"
        >
          <GeneralFeeds />
          <p>{{ $t("create.bulk.feedNav.general") }}</p>
          <tippy arrow="true" placement="right" a11y="false" interactive="true">
            <template #trigger class="tooltip-template">
              <TooltipIcon
                class="tooltip-template"
                :content="$t('create.bulk.feedNav.generalTooltip')"
              />
            </template>
            <div class="tooltip-content">
              <p>
                {{ $t("create.bulk.feedNav.generalTooltip") }}
              </p>
              <img
                src="@/assets/generic-feed-compress.png"
                alt="general-feed-image"
                class="generic-feed-image"
              />
            </div>
          </tippy>
        </button>
        <button
          class="feed-nav-button"
          :class="{ active: activeFeedType === 'product' }"
          @click="setActiveFeedType('product')"
        >
          <ProductFeeds />
          <p>{{ $t("create.bulk.feedNav.product") }}</p>
          <TooltipIcon
            v-tippy="{ placement: 'right', arrow: true }"
            :content="$t('create.bulk.feedNav.productTooltip')"
          />
        </button>
      </div>
      <AutocompletedSearch
        class="products-search"
        :items="filteredFeedList"
        :type="'feed'"
        :is-for-feeds="true"
        @search="onAutocompletedOptionSelected"
      />
    </div>
    <div class="feed-manager-header">
      <div class="list-element" @click="sortFeedList('feedName')">
        <p class="list-title">
          {{ $t("create.bulk.headerElements.names")
          }}{{ filteredFeedList.length }}
        </p>
        <div class="sorting-arrows">
          <SortArrow
            :class="{
              active: sortBy === 'feedName' && sortDirection === 'asc',
            }"
          />
          <SortArrow
            class="reverse-arrow"
            :class="{
              active: sortBy === 'feedName' && sortDirection === 'desc',
            }"
          />
        </div>
      </div>
      <div class="list-element" @click="sortFeedList('modificationDate')">
        <p class="list-title">
          {{ $t("create.bulk.headerElements.modified") }}
        </p>
        <div class="sorting-arrows">
          <SortArrow
            :class="{
              active: sortBy === 'modificationDate' && sortDirection === 'asc',
            }"
          />
          <SortArrow
            class="reverse-arrow"
            :class="{
              active: sortBy === 'modificationDate' && sortDirection === 'desc',
            }"
          />
        </div>
      </div>
      <div class="list-element" @click="sortFeedList('videos')">
        <p class="list-title">
          {{ $t("create.bulk.headerElements.videos") }}
        </p>
        <div class="sorting-arrows">
          <SortArrow
            :class="{ active: sortBy === 'videos' && sortDirection === 'asc' }"
          />
          <SortArrow
            class="reverse-arrow"
            :class="{ active: sortBy === 'videos' && sortDirection === 'desc' }"
          />
        </div>
      </div>
      <div class="list-element" @click="sortFeedList('segmentation')">
        <p class="list-title">
          {{ $t("create.bulk.headerElements.pages") }}
        </p>
        <div class="sorting-arrows">
          <SortArrow
            :class="{
              active: sortBy === 'segmentation' && sortDirection === 'asc',
            }"
          />
          <SortArrow
            class="reverse-arrow"
            :class="{
              active: sortBy === 'segmentation' && sortDirection === 'desc',
            }"
          />
        </div>
      </div>
      <div class="list-element">
        <p class="list-title">
          {{ "" }}
        </p>
      </div>
    </div>
    <div class="feed-cards-container">
      <div
        v-for="(feed, index) in filteredFeedList"
        :key="index"
        :ref="`feed-${feed.originalFeedIndex}`"
        class="feed-card"
        :class="{
          'edited-feed': feed.originalFeedIndex === lastEditedFeedIndex,
        }"
      >
        <div class="feed-name-and-date">
          <input
            v-model="feed.feedName"
            v-tippy="{ placement: 'top', arrow: true }"
            class="feed-name-input"
            :content="feed.feedName"
            @input="onFeedNameInput(feed.originalFeedIndex, feed.feedName)"
          />
          <p v-if="feed.creationDate" class="date">
            {{ $t("campaignManager.creationDate") }}
            {{ formattedDates(feed.creationDate) }}
          </p>
        </div>
        <div class="feed-info">
          <p v-if="feed.modificationDate" class="modification-date">
            {{ formattedDates(feed.modificationDate) }}
          </p>
          <p v-else class="modification-date">
            {{ formattedDates(feed.creationDate) }}
          </p>
        </div>
        <div class="feed-info">
          <p
            class="number-of-videos"
            :class="{ 'there-are-videos': feed.videos.length > 0 }"
          >
            <span class="bold">
              {{ feed.videos.length }}
            </span>
            {{
              feed.videos.length === 1
                ? $t("account.usage.video")
                : $t("account.usage.videos")
            }}
          </p>
          <button
            class="add-manage"
            @click="openElementsSlidingModal(feed.originalFeedIndex)"
          >
            {{ $t("create.bulk.mainButtons.videos") }}
          </button>
        </div>
        <div class="feed-info">
          <p
            class="number-of-videos"
            :class="{ 'there-are-videos': getAmountOfSegmentation(feed) > 0 }"
          >
            <span class="bold">
              {{ getAmountOfSegmentation(feed) }}
            </span>
            {{
              getAmountOfSegmentation(feed) === 1
                ? $t("account.usage.page")
                : $t("account.usage.pages")
            }}
          </p>
          <button
            class="add-manage"
            @click="openUrlsSlidingModal(feed.originalFeedIndex)"
          >
            {{ $t("create.bulk.mainButtons.urls") }}
          </button>
        </div>
        <button
          class="option-button"
          @click="openOptionsModal(feed.originalFeedIndex)"
        >
          <img
            src="@/assets/campaign-options-button.png"
            alt="options-button"
          />
        </button>
        <OptionsFeedModal
          v-if="showOptionsFeedModal[feed.originalFeedIndex]"
          :current-feed-edit-index="index"
          @clone-feed="handleCloneFeed(feed.originalFeedIndex)"
          @open-delete-feed-modal="openDeleteFeedModal(feed.originalFeedIndex)"
          @close-options-modal="handleCloseOptionsModal(feed.originalFeedIndex)"
        />
      </div>
    </div>
    <small-modal
      v-if="showDeleteFeedModal"
      :title="$t('create.bulk.deleteFeedModal.title')"
      class="delete-feed-modal"
      :fit-content="true"
      @close="closeDeleteFeedModal"
    >
      <div class="delete-feed-modal-container">
        <h3 class="subtitle">
          {{ $t("create.bulk.deleteFeedModal.subtitle") }}
        </h3>
        <banner-modal
          :title="$t('deleteCampaign.information')"
          :text="$t('create.bulk.deleteFeedModal.banner')"
        >
        </banner-modal>
        <div class="buttons">
          <button
            class="button-primary --grey"
            type="button"
            @click.prevent="closeDeleteFeedModal"
          >
            {{ $t("deleteCampaign.cancel") }}
          </button>
          <button class="button-primary button-flex" @click="deleteFeed()">
            {{ $t("deleteCampaign.delete") }}
            <DeleteIcon class="delete-icon" />
          </button>
        </div>
      </div>
    </small-modal>
    <transition name="modal-slide">
      <urls-sliding-modal
        v-if="showUrlsModal"
        :current-feed-edit-index="currentFeedEditIndex"
        @close-urls-modal="closeUrlsModal"
      ></urls-sliding-modal>
    </transition>
    <transition name="modal-slide">
      <elements-sliding-modal
        v-if="showElementsModal"
        :current-feed-edit-index="currentFeedEditIndex"
        :campaign-id="campaign._id"
        :is-edit-bulk-format="isEditBulkFormat"
        :is-bulk="true"
        @close-elements-modal="closeElementsModal"
      >
      </elements-sliding-modal>
    </transition>
    <transition name="modal-slide">
      <embed-code-sliding-modal
        v-if="showEmbedCodeModal"
        :campaign="getFullCampaign"
        @close-embed-code-modal="closeEmbedCodeModal"
      ></embed-code-sliding-modal>
    </transition>
  </div>
</template>

<script>
import CloseIcon from "@/assets/svg/settings/close.svg?inline";
import PlayIcon from "@/assets/svg/small-play-icon.svg?inline";
import CodeIcon from "@/assets/svg/embed-code-icon.svg?inline";
import UrlsSlidingModal from "@/components/create-campaign-steps/UrlsSlidingModal.vue";
import ElementsSlidingModal from "@/components/create-campaign-steps/ElementsSlidingModal.vue";
import SmallModal from "@/components/shared/SmallModal.vue";
import BannerModal from "@/components/shared/BannerModal.vue";
import DeleteIcon from "@/assets/svg/delete-small.svg?inline";
import { mapState, mapGetters } from "vuex";
import EmbedCodeSlidingModal from "@/components/create-campaign-steps/EmbedCodeSlidingModal.vue";
import GeneralFeeds from "@/assets/svg/general-feeds.svg?inline";
import ProductFeeds from "@/assets/svg/product-feeds.svg?inline";
import TooltipIcon from "@/assets/svg/tooltip-icon.svg?inline";
import PlusIcon from "@/assets/svg/plus-icon.svg?inline";
import SortArrow from "@/assets/svg/sort-arrow.svg?inline";
import OptionsFeedModal from "@/components/create-campaign-steps/OptionsFeedModal.vue";
import AutocompletedSearch from "@/components/shared/AutocompletedSearch.vue";
import { tagMixin } from "@/utils/add-bulk-tag.js";
import enums from "../../enums";

const { FormatType, DeviceType } = enums;

export default {
  components: {
    CloseIcon,
    PlayIcon,
    CodeIcon,
    UrlsSlidingModal,
    SmallModal,
    BannerModal,
    DeleteIcon,
    ElementsSlidingModal,
    EmbedCodeSlidingModal,
    OptionsFeedModal,
    GeneralFeeds,
    ProductFeeds,
    TooltipIcon,
    PlusIcon,
    SortArrow,
    AutocompletedSearch,
  },
  mixins: [tagMixin],
  props: {
    isEditMode: { type: Boolean, default: true },
  },
  data() {
    return {
      showUrlsModal: false,
      showDeleteFeedModal: false,
      showElementsModal: false,
      currentFeedEditIndex: null,
      showEmbedCodeModal: false,
      isEditBulkFormat: false,
      feedIndexToDelete: null,
      wasEmbedCodeOpened: false,
      copy: "Copy coupon code",
      cart: "Add to cart",
      email: "Email collector",
      link: "Link to page",
      showOptionsFeedModal: [],
      savedScrollPosition: 0,
      lastEditedFeedIndex: null,
      activeFeedType: "general",
      sortBy: null,
      sortDirection: "asc",
    };
  },
  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      siteId: "account/getSiteId",
      campaign: "campaign/getSingleCampObj",
      currentFeedEdit: "campaign/currentFeedEdit",
      feedList: "campaign/feedList",
      defaultStoryFormatObject: "campaign/defaultStoryFormatObject",
      defaultCarouselFormatObject: "campaign/defaultCarouselFormatObject",
      defaultFormatObject: "campaign/defaultFormatObject",
      defaultEmbedObject: "campaign/defaultEmbedFormatObject",
      defaultBubbleProperties: "campaign/defaultBubbleProperties",
      getTags: "tag/getTags",
    }),
    segmentationAmount() {
      return this.feedList.reduce(
        (total, feed) => total + this.getAmountOfSegmentation(feed),
        0
      );
    },
    videosAmount() {
      return this.feedList.reduce(
        (total, feed) => total + feed.videos.length,
        0
      );
    },
    getFullCampaign() {
      return {
        ...this.campaign,
        bulk: this.feedList,
      };
    },
    isSlidingModalOpen() {
      return (
        this.showUrlsModal || this.showElementsModal || this.showEmbedCodeModal
      );
    },
    lastSavedTime() {
      if (!this?.campaign?.modificationDate) {
        return "";
      }

      const modificationDate = new Date(this.campaign.modificationDate);
      const currentDate = new Date();
      const diffInMs = currentDate - modificationDate;
      const diffInSeconds = Math.floor(diffInMs / 1000);

      // Determine the correct prefix
      const isEnglish = this.currentLanguage === "en";
      const prefix = isEnglish
        ? ""
        : `${this.$t("create.bulk.lastSave.languageNeed")} `;

      if (diffInSeconds < 60) {
        return `${prefix}${diffInSeconds} ${this.$t(
          "create.bulk.lastSave.seconds"
        )}`;
      }

      const diffInMinutes = Math.floor(diffInSeconds / 60);
      if (diffInMinutes < 60) {
        return `${prefix}${diffInMinutes} ${this.$t(
          "create.bulk.lastSave.minutes"
        )}`;
      }

      const diffInHours = Math.floor(diffInMinutes / 60);
      if (diffInHours < 24) {
        return `${prefix}${diffInHours} ${this.$t(
          "create.bulk.lastSave.hours"
        )}`;
      }

      const diffInDays = Math.floor(diffInHours / 24);
      if (diffInDays < 7) {
        return `${prefix}${diffInDays} ${this.$t("create.bulk.lastSave.days")}`;
      }

      const diffInWeeks = Math.floor(diffInDays / 7);
      if (diffInDays < 30) {
        return `${prefix}${diffInWeeks} ${this.$t(
          "create.bulk.lastSave.weeks"
        )}`;
      }

      const diffInMonths = Math.floor(diffInDays / 30);
      return `${prefix}${diffInMonths} ${this.$t(
        "create.bulk.lastSave.months"
      )}`;
    },
    currentLanguage() {
      return this.$i18n.locale;
    },
    filteredFeedList() {
      if (this.activeFeedType === "product") {
        return this.feedList.filter(
          (feed) => feed.segmentation?.productId?.length === 1
        );
      } else {
        return this.feedList.filter(
          (feed) => feed.segmentation?.productId?.length !== 1
        );
      }
    },
    isPopup() {
      return this.campaign?.formats[0]?.formatType === FormatType.popup;
    },
    isBubble() {
      return this.campaign?.formats[0]?.formatType === FormatType.bubble;
    },
  },
  async created() {
    try {
      const { goal, type, isEditMultipleFeed, feedIndex } = this.$route.query;

      if (this.isEditMode) {
        await this.$store.dispatch("campaign/getSingle", {
          campaignId: this.$route.params.campaignId,
          siteId: this.siteId,
        });
      } else {
        const formats = this.getFormatsObject({ goal, type });
        const tag = await this.addBulkTag();

        await this.$store.dispatch("campaign/createBulkCampaign", {
          siteId: this.siteId,
          formats,
          tags: [tag._id],
        });
      }
      this.$store.commit("campaign/setFeedList", this.campaign.bulk);
      if (isEditMultipleFeed && feedIndex !== undefined) {
        const index = parseInt(feedIndex, 10);
        if (index >= 0 && index < this.feedList.length) {
          this.lastEditedFeedIndex = index;

          this.$nextTick(() => {
            const feedElement = this.$refs[`feed-${index}`];
            if (feedElement && feedElement[0]) {
              feedElement[0].scrollIntoView({ behavior: "smooth" });
            }
          });

          setTimeout(() => {
            this.lastEditedFeedIndex = null;
          }, 5000);
        }
      }
    } catch (err) {
      this.$notify({
        title: this.$t("shared.toastMessage.error"),
        text: err,
        type: "error",
      });
      throw err;
    }
    this.feedList.forEach(() => {
      this.showOptionsFeedModal.push(false);
    });
  },
  methods: {
    openUrlsSlidingModal(index) {
      this.currentFeedEditIndex = index;
      this.$store.commit("campaign/setCurrentFeedEdit", this.feedList[index]);
      this.savedScrollPosition = window.pageYOffset;
      this.showUrlsModal = true;
    },
    openElementsSlidingModal(index, isFormatEdit = false) {
      //This way we know if we are editing Formats or Videos elements
      this.isEditBulkFormat = isFormatEdit;

      this.currentFeedEditIndex = index;
      this.$store.commit("campaign/setCurrentFeedEdit", this.feedList[index]);
      this.savedScrollPosition = window.pageYOffset;

      if (this.isEditBulkFormat && !this.feedList[0]?.videos) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.addVideosFirstFeed"),
          type: "error",
        });
      }

      this.showElementsModal = true;
    },
    openEmbedCodeModal() {
      if (!this.feedList.length || !this.feedList[0]?.videos?.length) {
        return this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.firstFeedCode"),
          type: "error",
        });
      }
      this.savedScrollPosition = window.pageYOffset;
      this.showEmbedCodeModal = true;
      this.wasEmbedCodeOpened = true;
    },
    closeUrlsModal() {
      this.lastEditedFeedIndex = this.currentFeedEditIndex;
      this.showUrlsModal = false;
      this.scrollToSavedPosition();
      setTimeout(() => {
        this.lastEditedFeedIndex = null;
      }, 5000);
    },
    closeElementsModal() {
      if (!this.isEditBulkFormat) {
        this.lastEditedFeedIndex = this.currentFeedEditIndex;
      }
      this.showElementsModal = false;
      this.scrollToSavedPosition();
      if (!this.isEditBulkFormat) {
        setTimeout(() => {
          this.lastEditedFeedIndex = null;
        }, 5000);
      }
    },
    closeEmbedCodeModal() {
      this.showEmbedCodeModal = false;
      this.scrollToSavedPosition();
    },

    goToCampaignManager(closeBulkView = false) {
      const query = {};

      if (
        !this.isEditMode &&
        !this.wasEmbedCodeOpened &&
        !closeBulkView &&
        this.campaign?.formats[0]?.formatType !== FormatType.bubble &&
        this.campaign?.formats[0]?.formatType !== FormatType.popup
      ) {
        query.showEmbed = true;
      }

      this.$router.push({
        name: "campaignManager",
        params: {
          campaignId: this.campaign._id,
        },
        query,
      });
    },

    async createFeed() {
      this.setActiveFeedType("general");
      const newFeed = {
        feedName: "Feed name " + (this.feedList.length + 1),
        segmentation: {},
        videos: [],
        creationDate: new Date().toISOString(),
        modificationDate: new Date().toISOString(),
      };

      await this.$store.dispatch("campaign/createFeed", {
        campaignId: this.campaign._id,
        newFeed,
      });
      this.showOptionsFeedModal.push(false);
      this.lastEditedFeedIndex = this.feedList.length - 1;

      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.bulk.notification.createFeed"),
        type: "success",
      });

      this.scrollToEndOfPage();

      setTimeout(() => {
        this.lastEditedFeedIndex = null;
      }, 5000);
    },
    onFeedNameInput(index, name) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.timeout = setTimeout(() => {
        const updatedFeed = {
          ...this.feedList[index],
          feedName: name,
        };

        this.$store
          .dispatch("campaign/updateFeed", {
            campaignId: this.campaign._id,
            feedIndex: index,
            feedData: updatedFeed,
          })
          .then(() => {
            this.$store.commit("campaign/updateFeedList", {
              currentFeedEdit: updatedFeed,
              index: index,
            });
          });

        this.timeout = null;
      }, 500);
    },
    openDeleteFeedModal(index) {
      this.feedIndexToDelete = index;
      this.showDeleteFeedModal = true;
    },
    closeDeleteFeedModal() {
      this.showDeleteFeedModal = false;
    },
    deleteFeed() {
      this.$store.dispatch("campaign/deleteFeed", {
        campaignId: this.campaign._id,
        feedIndex: this.feedIndexToDelete,
      });

      this.showOptionsFeedModal.splice(this.feedIndexToDelete, 1);
      this.closeDeleteFeedModal();
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.bulk.notification.deleteFeed"),
        type: "success",
      });
    },
    getAmountOfSegmentation(feed) {
      if (feed.segmentation.advanced) {
        if (!feed.segmentation.advanced[0]?.filters) {
          return 1;
        } else {
          return feed.segmentation.advanced[0]?.filters.length;
        }
      }

      if (!feed.segmentation.url || !feed.segmentation.url.length) {
        return feed.segmentation.productId
          ? feed.segmentation.productId.length
          : 0;
      } else if (!feed.segmentation.url[0]?.filters) {
        return feed.segmentation.productId
          ? feed.segmentation.productId.length + 1
          : 1;
      } else {
        return (
          feed.segmentation.url[0]?.filters.length +
          (feed.segmentation.productId ? feed.segmentation.productId.length : 0)
        );
      }
    },
    getFormatsObject({ goal, type }) {
      switch (goal) {
        case "carousel":
          if (type === "fullScreen")
            return [
              {
                ...this.defaultFormatObject,
                ...this.defaultCarouselFormatObject,
                isFullScreen: true,
                carouselThumbnailAmount: 1,
              },
            ];
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultCarouselFormatObject,
              isFullScreen: false,
            },
          ];
        case "stories":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultStoryFormatObject,
            },
          ];
        case "embed":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultEmbedObject,
            },
          ];
        case "bubble":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              deviceType: DeviceType.mobile,
            },
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              deviceType: DeviceType.desktop,
            },
          ];
        case "fullscreen-bubble":
          return [
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              isFullScreen: true,
              deviceType: DeviceType.mobile,
            },
            {
              ...this.defaultFormatObject,
              ...this.defaultBubbleProperties,
              isFullScreen: true,
              deviceType: DeviceType.desktop,
            },
          ];
        case "popup":
          return [
            {
              ...this.defaultFormatObject,
              formatType: FormatType.popup,
              deviceType: DeviceType.mobile,
            },
            {
              ...this.defaultFormatObject,
              formatType: FormatType.popup,
              deviceType: DeviceType.desktop,
            },
          ];
      }
    },
    uniqueCTATypes(feed) {
      const uniqueTypes = new Set();
      feed.videos.forEach((video) => {
        if (video.cta) {
          uniqueTypes.add(video.cta.ctaType);
        }
      });
      return Array.from(uniqueTypes);
    },
    openOptionsModal(index) {
      this.currentFeedEditIndex = index;
      this.showOptionsFeedModal = this.showOptionsFeedModal.map(
        (value, i) => i === index
      );
    },
    handleCloseOptionsModal(index) {
      this.$set(this.showOptionsFeedModal, index, false);
    },
    handleCloneFeed(index) {
      const feedToClone = this.feedList[index];

      const clonedFeed = {
        ...feedToClone,
        feedName: `${feedToClone.feedName} (Copy)`,
        creationDate: new Date().toISOString(),
        modificationDate: new Date().toISOString(),
      };

      this.$store.dispatch("campaign/createFeed", {
        campaignId: this.campaign._id,
        newFeed: clonedFeed,
      });

      this.showOptionsFeedModal.push(false);
      this.handleCloseOptionsModal(index);
      this.$notify({
        title: this.$t("shared.toastMessage.success"),
        text: this.$t("create.bulk.notification.cloneFeed"),
        type: "success",
      });
      this.scrollToEndOfPage();
    },
    formattedDates(date) {
      const invoicingDate = date ? new Date(date) : new Date();
      const month = invoicingDate.toLocaleString(this.$i18n.locale, { month: "short" });
      const day = invoicingDate.getDate(); // Correct way to get the day
      const year = invoicingDate.getFullYear(); // Correct way to get the year

      return `${month} ${day}, ${year}`;
    },
    scrollToEndOfPage() {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      }, 100);
    },
    scrollToSavedPosition() {
      setTimeout(() => {
        window.scrollTo({ top: this.savedScrollPosition, behavior: "smooth" });
      }, 100);
    },
    setActiveFeedType(type) {
      this.activeFeedType = type;
    },
    sortFeedList(property) {
      if (this.sortBy === property) {
        this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.sortBy = property;
        this.sortDirection = "asc";
      }

      this.filteredFeedList.sort((a, b) => {
        let valueA, valueB;

        switch (property) {
          case "feedName":
            valueA = a.feedName.toLowerCase();
            valueB = b.feedName.toLowerCase();
            break;
          case "modificationDate":
            valueA = a.modificationDate || a.creationDate;
            valueB = b.modificationDate || b.creationDate;
            break;
          case "videos":
            valueA = a.videos.length;
            valueB = b.videos.length;
            break;
          case "segmentation":
            valueA = this.getAmountOfSegmentation(a);
            valueB = this.getAmountOfSegmentation(b);
            break;
          default:
            return 0;
        }

        if (valueA < valueB) {
          return this.sortDirection === "asc" ? -1 : 1;
        }
        if (valueA > valueB) {
          return this.sortDirection === "asc" ? 1 : -1;
        }
        return 0;
      });
    },
    onAutocompletedOptionSelected(item) {
      if (item !== undefined) {
        const selectedFeedIndex = this.filteredFeedList.findIndex(
          (feed) => feed.originalFeedIndex === item.originalFeedIndex
        );
        if (selectedFeedIndex !== -1) {
          this.scrollToFeed(selectedFeedIndex);
        }
      }
    },
    scrollToFeed(originalFeedIndex) {
      const feedElement = this.$refs[`feed-${originalFeedIndex}`];
      if (feedElement && feedElement[0]) {
        feedElement[0].scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
  },
};
</script>

<style lang="scss">
.feed-list-header .list-header {
  grid-template-columns: 2.5fr 1fr 1fr 1fr 0.5fr !important;
}
</style>

<style lang="scss" scoped>
.bulk-embed-container {
  padding: 30px 74px;
}

.bulk-embed-fixed {
  position: fixed;
}

.close-icon {
  top: 10px;
  right: 10px;
  background: none;
  position: fixed;
}

.bulk-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;
}

.bulk-title {
  @include base-font;
  @include font-big;
  font-weight: 700;
}

.header-button {
  display: flex;
  align-items: center;
  gap: 16px;
}

.bulk-action-button {
  padding: 8px 24px;
  height: 35px;
  box-sizing: border-box;
  border: 1px solid $dark-purple;
  border-radius: 4px;
  background-color: white;
  @include base-font;
  @include font-small;
  color: $dark-purple;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    path {
      fill: $dark-purple;
    }
  }
  &:hover {
    background-color: rgba(226, 124, 252, 0.1);
  }
}

.first-button {
  border-radius: 3px 0px 0px 3px;
  border-right: none;
}

.second-button {
  border-radius: 0 3px 3px 0;
}

.last-saved {
  padding: 8px 12px;
  @include base-font;
  @include font-smaller;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 12px;
  color: $light-grey;
}

.save-icon {
  path {
    fill: white;
  }
}

.feed-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.feed-title {
  @include base-font;
  @include font-big;
}
.number-feeds {
  @include base-font;
  @include font-normal;
}

.feed-cards-container {
  display: flex;
  flex-direction: column;
}

.feed-card {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr 0.5fr;
  place-items: flex-start;
  align-items: center;
  border-bottom: 1px solid $light-grey;
  padding: 10px 12px;
  box-sizing: content-box;
  position: relative;
  &:first-child {
    border-top: 1px solid $light-grey;
  }
}
.feed-name-input {
  @include base-font;
  @include font-normal;
  border: none;
  background-color: transparent;
  @media (min-width: 1400px) {
    width: 90%;
  }
}

.feed-info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 4px;
}

.delete-feed-card {
  width: 100%;
  text-align: end;
  .delete-icon-card {
    transform: scale(225%);
    margin-right: 8px;
    cursor: pointer;
    path {
      fill: $light-grey;
    }
  }
}

.delete-feed-modal-container {
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.subtitle {
  text-align: center;
  @include font-normal;
  font-weight: 400;
}

.delete-icon {
  transform: scale(175%);
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 24px;
}

.button-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.submit-button {
  @include base-font;
  @include font-small;
  font-weight: 500;
  background: none;
  border: none;
}

.--red {
  box-shadow: 2px 2px 15px 0px rgba(235, 0, 0, 0.25);
  &:hover {
    background-color: rgba(235, 0, 0, 0.25);
  }
}

.feed-info-elements {
  display: flex;
  align-items: center;
  gap: 16px;
}

.elements-icons {
  display: flex;
  align-items: center;
  gap: 8px;
}

.cta-icon {
  transform: scale(115%);
  outline: none;
}
.option-button {
  background: transparent;
}

.feed-name-and-date {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 90%;
}

.date {
  @include font-smallest;
  color: $light-grey;
  padding-left: 2px;
}
.edited-feed {
  background-color: $light-pink;
}
.feed-nav {
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
}
.nav-buttons {
  display: flex;
}

.feed-nav-button {
  padding: 8px 16px;
  @include base-font;
  @include font-small;
  font-weight: 400;
  cursor: pointer;
  background: none;
  color: $dark-purple;
  display: flex;
  align-items: center;
  border-bottom: 3px solid rgba(196, 196, 196, 1);
  p {
    margin-left: 8px;
    margin-right: 4px;
  }
  &.active {
    border-bottom: 3px solid $dark-purple;
  }
}

.create-feed {
  background-color: $dark-purple;
  color: white;
  svg {
    path {
      fill: white;
    }
  }
  &:hover {
    color: $dark-purple;
    svg {
      path {
        fill: $dark-purple;
      }
    }
  }
}
.tooltip-template {
  margin-top: 3px;
}

.tooltip-content {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  p {
    font-size: 14px;
    max-width: 300px;
    text-align: left;
  }
}

.number-of-videos {
  @include font-smaller;
  font-weight: 400;
  background-color: #e2e2e2;
  width: fit-content;
  border-radius: 20px;
  padding: 4px 12px;
  span {
    font-weight: 600;
  }
}
.there-are-videos {
  background-color: #d4f8d3;
}
.add-manage {
  @include base-font;
  @include font-smaller;
  color: $dark-purple;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  height: 20px;
}
.bold {
  font-weight: bold;
}

.modification-date {
  @include base-font;
  @include font-smaller;
}

.generic-feed-image {
  max-width: 280px;
}

.feed-manager-header {
  display: grid;
  grid-template-columns: 2.5fr 1fr 1fr 1fr 0.5fr;
  place-items: flex-start;
  align-items: center;
  position: sticky;
  display: grid;
  color: #585858;
  background: #ececec;
  border: 1px solid #ececec;
  box-sizing: border-box;
  border-radius: 2px;
  align-items: center;
  text-align: start;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  height: 100%;
  padding: 14px;
}
.list-element {
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
}
.sorting-arrows {
  display: flex;
  flex-direction: column;
  gap: 6px;
  transform: scale(60%);
  path {
    fill: $dark-grey;
  }
  .active path {
    fill: $light-purple;
  }
}
.reverse-arrow {
  transform: translateX(-0.9px);
  path {
    transform: rotate(180deg);
    transform-origin: 50% 50%;
  }
}
</style>
