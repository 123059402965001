<template>
  <div class="format-block" :class="{ 'bubble-text-wrapper': isBubble }">
    <h3 class="format-block-title" :class="{ 'bubble-text-title': isBubble }">
      {{
        formatType === 1 && isMultiple
          ? $t("create.step3.textBubble")
          : $t("create.step3.text")
      }}
    </h3>
    <input
      class="text-input --with-borders"
      :class="{ 'input-wider': isMultiple, 'bubble-text-input': isBubble }"
      type="text"
      maxlength="50"
      :value="campaignTitle"
      :placeholder="$t('create.step3.textPlaceholder')"
      @input="$emit('campaign-title', $event.target.value)"
    />
  </div>
</template>

<script>
export default {
  props: {
    campaignTitle: {
      type: String,
      default: "",
    },
    isMultiple: {
      type: Boolean,
      default: false,
    },
    formatType: {
      type: Number,
      default: 0,
    },
    isBubble: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.bubble-text-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  box-shadow: none;
  padding: 0;
  margin: 0;
  width: 100%;
  &:hover {
    background: $settings-hover;
  }
}
.bubble-text-title {
  @include font-small;
  font-weight: 400;
}
.input-wider {
  width: 90%;
  margin-bottom: 10px;
}
.bubble-text-input {
  color: $dark-grey;
  width: 90%;
  font-size: 14px;
}
</style>
