<template>
  <section class="step-2-wrapper">
    <button v-if="!isBulk" class="close-icon" @click="backToCampaignManager">
      <close-icon></close-icon>
    </button>
    <div class="video-upload" :class="{ 'bulk-video-upload': isBulk }">
      <h1 v-if="!isBulk" class="step-title">
        {{ $t("create.step2.heading") }}
        <span class="bold"> {{ $t("create.step2.subHeading") }} </span>
      </h1>
      <div
        class="upload-video-area"
        :style="uploadBlockBackground"
        :multiple="true"
        @dragover.prevent="changeBackground = true"
        @dragleave.prevent="changeBackground = false"
        @drop.prevent="onFileChanged"
        @click="$refs.fileInput.click()"
      >
        <section class="flex-centered">
          <button class="upload-button">
            {{ $t("create.step2.uploadZone.button") }}
          </button>
          <p class="upload-drop-text">
            {{ $t("create.step2.uploadZone.orDrop") }}
          </p>
        </section>
        <p class="upload-formats">
          {{ $t("create.step2.uploadZone.formats") }}
        </p>
        <input
          id="fileUploaded"
          ref="fileInput"
          class="hidden"
          multiple
          type="file"
          accept="video/*"
          @change="onFileChanged"
        />
      </div>
      <div
        class="quick-import-area"
        :class="{ 'bulk-quick-import-area': isBulk }"
      >
        <div class="quick-import-heading">
          {{ $t("create.step2.quickImport") }}
        </div>
        <div
          class="quick-import-buttons"
          :class="{ 'bulk-quick-import-buttons': isBulk }"
        >
          <button class="quick-import-button" @click="openVideoLibrary = true">
            <VidjetLogo class="icon-logo" />
            <p>{{ $t("create.step2.videoLibrary") }}</p>
          </button>
          <google-picker
            class="quick-import-button"
            @fileInput="onFileChanged"
          ></google-picker>
          <button
            class="quick-import-button"
            @click="toggleImporterModal('youtube')"
          >
            <img
              :src="require('../../assets/youtube-logo.svg')"
              class="youtube-logo"
              alt="logo youtube"
            />
            <span>Youtube</span>
          </button>
          <button
            class="quick-import-button"
            @click="toggleImporterModal('tiktok')"
          >
            <img
              :src="require('../../assets/tiktok-logo.svg')"
              class="tiktok-logo"
              alt="logo tiktok"
            />
            <span>TikTok</span>
            <open-in-new class="open-new-link"></open-in-new>
          </button>
          <button
            v-if="shouldShowInstagram"
            class="quick-import-button"
            @click="toggleImporterModal('instagram')"
          >
            <img
              :src="require('../../assets/instagram-logo.svg')"
              class="instagram-logo"
              alt="logo instagram"
            />
            <span>Instagram</span>
            <open-in-new class="open-new-link"></open-in-new>
          </button>
          <button
            v-else
            class="quick-import-button"
            @click="toggleImporterModal('instagram-disabled')"
          >
            <img
              :src="require('../../assets/instagram-logo.svg')"
              class="instagram-logo"
              alt="logo instagram"
            />
            <span>Instagram</span>
            <open-in-new class="open-new-link"></open-in-new>
          </button>
        </div>
      </div>
      <div
        class="premade-video-block"
        :class="{ 'bulk-premade-video-block': isBulk }"
      >
        <h1
          v-tippy="{ arrow: true, placement: 'top' }"
          class="premade-video-block-title"
          :content="$t('create.step2.tooltip')"
        >
          {{ $t("create.step2.template") }}
          <interrogation-mark></interrogation-mark>
        </h1>

        <div class="premade-video-container">
          <div class="premade-video-grid">
            <div v-for="premadeVideo in premadeVideos" :key="premadeVideo.id">
              <div class="card-video" @click="choosePremadeVideo(premadeVideo)">
                <img :src="premadeVideo.image" class="video-image" />
                <h3 class="campaign-type">
                  {{ premadeVideo.text[i18nLocale] }}
                </h3>
              </div>
            </div>
          </div>
          <vidjet-accordion :has-border="false" :centered-title="true">
            <template #title>
              <span>{{ $t("create.step2.seeMore") }}</span>
            </template>
            <template #content>
              <div class="premade-video-grid">
                <div
                  v-for="premadeVideo in premadeVideoAccordion"
                  :key="premadeVideo.id"
                >
                  <div
                    class="card-video"
                    @click="choosePremadeVideo(premadeVideo)"
                  >
                    <img :src="premadeVideo.image" class="video-image" />
                    <h3 class="campaign-type">
                      {{ premadeVideo.text[i18nLocale] }}
                    </h3>
                  </div>
                </div>

                <div>
                  <a
                    class="need-more"
                    :href="`mailto:contact@vidjet.io?subject=${$t(
                      'create.step2.mailTo'
                    )}`"
                    target="_blank"
                  >
                    <p>{{ $t("create.step2.needMore") }}</p>
                  </a>
                  <p class="see-example">
                    {{ $t("shared.buttons.contactUs") }}
                  </p>
                </div>
              </div>
            </template>
          </vidjet-accordion>
        </div>
      </div>
      <!--  -->
      <div class="settings-footer-container">
        <edit-footer
          v-if="isEditMode && !isBulk"
          :next-text="$t('shared.buttons.save')"
          :disabled-next="!selectedVideo"
          @prev="$router.push({ name: 'editCampaign' })"
          @next="editSave"
        />
        <bulk-embed-confirm
          v-else-if="isBulk"
          @confirm="editSave"
          @cancel="closeVideoModal"
        />
        <settings-footer
          v-else
          @prev="goPreviousStep"
          @next="goNextStep"
        ></settings-footer>
      </div>
    </div>
    <div class="preview-wrapper" :class="{ 'bulk-preview-wrapper': isBulk }">
      <vidjet-preview
        :is-edit-mode="isEditMode"
        :is-embed="true"
        :is-step2="true"
        :preview-campaign="selectedVideo"
      ></vidjet-preview>
      <video-carousel-preview
        :carousel="files"
        @carousel-video-selected="selectVideoInPreview"
        @carousel-video-deleted="removeVideo"
        @carousel-video-reorder="reorderVideo"
      >
      </video-carousel-preview>
    </div>

    <play-error-modal
      :show-modal="showModal"
      @toggleModal="togglePlayErrorModal"
    ></play-error-modal>
    <small-modal
      v-if="showImporterModal && selectedImporter === 'youtube'"
      :fit-content="true"
      @close="removeVideos"
    >
      <YoutubeImporter
        :youtube-urls="importYoutubeUrls"
        :is-loading="isLoading"
        @import-video="onFileChanged($event)"
        @remove-videos="removeVideos"
        @close="toggleImporterModal"
      />
    </small-modal>
    <small-modal
      v-if="showImporterModal && selectedImporter !== 'youtube'"
      :fit-content="true"
      @close="toggleImporterModal"
    >
      <div v-if="selectedImporter === 'instagram'" class="instagram-modal">
        <InstagramPicker
          :integration="instagramIntegration"
          @close-clicked="toggleImporterModal"
          @instagram-import="onInstagramImport($event)"
          @disconnectClicked="disconnectInstagram()"
        ></InstagramPicker>
      </div>
      <div v-else>
        <h3 class="importer-modal-title">
          {{ $t("create.step2.quickImportModal.title1") }}
          {{ selectedImporter | capitalize }}
          {{ $t("create.step2.quickImportModal.title2") }}
        </h3>
        <form
          class="importer-form"
          @input="importVideo(selectedImporter)"
          @submit.prevent="importVideo(selectedImporter)"
        >
          <div>
            <input
              id="importUrl"
              v-model="importUrl"
              class="input-importer"
              :class="importUrl ? 'input-importer-content' : ''"
              type="text"
              required
            />
          </div>
        </form>
      </div>
    </small-modal>
    <SlidingModal
      v-if="openVideoLibrary"
      class="video-library-modal"
      :slide-direction="slideDirection"
      @close="closeLibraryModal"
    >
      <VideoLibrary
        @play-video-in-preview="selectVideoInPreview"
        @import-videos="importVideosFromLibrary"
        @close-library="closeLibraryModal"
      />
    </SlidingModal>
    <spinner-loader v-if="isLoading"></spinner-loader>
    <spinner-loader
      v-if="showUploadProgress"
      :loading-text="$t('shared.loader.uploadingVideo')"
      :loading-text-additional="$t('shared.loader.uploadingVideo2')"
      :start-value="getUploadVideos.videosUploaded"
      :end-value="numberOfVideosToUpload"
      @videos-uploaded="editRedirect"
    />

    <PlanRegistration
      v-if="showPlanRegistrationModal"
      :new-plan="proposedPlan"
      :show-arrows="currentPlan !== 'scale'"
      @close="showPlanRegistrationModal = false"
    />
  </section>
</template>

<script>
import VidjetPreview from "../shared/Vidjet-preview.vue";
import VidjetAccordion from "../shared/VidjetAccordion.vue";
import CloseIcon from "@/assets/svg/settings/close.svg?inline";
import InterrogationMark from "@/assets/svg/settings/interrogation-mark.svg?inline";
import premadeCampaignVideos from "@/utils/premadeCampaignVideos.js";
import SettingsFooter from "./SettingsFooter.vue";
import { mapGetters, mapState } from "vuex";
import EditFooter from "@/components/create-campaign-steps/EditFooter";
import VidjetLogo from "@/assets/svg/vidjet-logo-library.svg?inline";
import SpinnerLoader from "../shared/SpinnerLoader.vue";
import { generateVideoCover } from "../../utils/generate-video-cover";
import enums from "../../enums";
import SmallModal from "../shared/SmallModal";
import api from "../../api";
import GooglePicker from "../shared/GooglePicker.vue";
import PlayErrorModal from "./PlayErrorModal.vue";
import VideoCarouselPreview from "../shared/VideoCarouselPreview.vue";
import VideoLibrary from "./VideoLibrary.vue";
import YoutubeImporter from "./YoutubeImporter.vue";
import InstagramPicker from "./InstagramPicker.vue";
import OpenInNew from "@/assets/svg/open-in-new.svg?inline";
import BulkEmbedConfirm from "./BulkEmbedConfirm.vue";
import PlanRegistration from "@/components/upgrade-plan/PlanRegistration.vue";
import SlidingModal from "@/components/shared/SlidingModal.vue";

const { FormatType } = enums;

export default {
  components: {
    VidjetPreview,
    CloseIcon,
    InterrogationMark,
    SettingsFooter,
    EditFooter,
    SpinnerLoader,
    VidjetAccordion,
    SmallModal,
    GooglePicker,
    PlayErrorModal,
    VideoCarouselPreview,
    VidjetLogo,
    VideoLibrary,
    InstagramPicker,
    OpenInNew,
    YoutubeImporter,
    BulkEmbedConfirm,
    PlanRegistration,
    SlidingModal,
  },

  beforeRouteEnter(t, f, next) {
    next(async (vm) => {
      vm.init();
    });
  },

  props: {
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isBulk: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      maxFileSize: 300,
      appsumoMaxFileSize: 1000,
      changeBackground: false,
      files: [],
      isPremade: false,
      isFileTooBig: false,
      userGoal: {},
      isLoading: false,
      name: {
        en: "Regular Widget",
        fr: "Widget Simple",
      },
      FormatType,
      showModal: false,
      showImporterModal: false,
      importUrl: "",
      importYoutubeUrls: [],
      selectedImporter: "",
      campaign: null,
      showUploadProgress: false,
      selectedVideo: {
        height: 400,
        width: 200,
        duration: 15,
        isPremade: false,
        url: "https://media.vidjet.io/New+default+preview.mp4",
        thumbnail: require("../../assets/new-preview-thumbnail.png"),
      },
      videoMetadataLoading: false,
      openVideoLibrary: false,
      showPlanRegistrationModal: false,
      proposedPlan: {},
      slideDirection: "right",
    };
  },

  computed: {
    ...mapState(["site"]),
    ...mapGetters({
      getSiteId: "account/getSiteId",
      getCampaignCreation: "campaign/campaignCreation",
      getCampaign: "campaign/campaignSelected",
      getIsLoggedIn: "account/getIsLoggedIn",
      videoObject: "campaign/videoObject",
      siteId: "account/getSiteId",
      currentPlan: "plan/currentPlan",
      videosInCampaignsLimit: "plan/videosInCampaignsLimit",
      getVideos: "video/getVideos",
      numberOfVideosInLibrary: "video/numberOfVideosInLibrary",
      getUploadVideos: "video/getUploadVideos",
      singleCampaign: "campaign/getSingle",
      currentFeedEdit: "campaign/currentFeedEdit",
      feedList: "campaign/feedList",
      getVideoLibrary: "video/getVideoLibrary",
    }),

    shouldShowInstagram() {
      // only for the moment to hide instagram on prod, should be removed soon
      return process.env.VUE_APP_URL === "https://dev-app.vidjet.io";
    },

    i18nLocale() {
      return this.$i18n.locale ? this.$i18n.locale : "en";
    },

    uploadBlockBackground() {
      const opacity = this.changeBackground ? "0.1" : "0.3";
      return `background: rgba(226, 124, 252, ${opacity})`;
    },

    hasVideoSet() {
      return Boolean(this.selectedVideo);
    },

    isEmbed() {
      return (
        this.userGoal === "embed" ||
        this.campaign?.formats[0]?.formatType === FormatType.embed
      );
    },

    premadeVideos() {
      return premadeCampaignVideos.all;
    },
    // if from scratch display all videos
    premadeVideoAccordion() {
      return premadeCampaignVideos.accordion;
    },
    numberOfVideosToUpload() {
      return this.files.filter((file) => !file._id).length;
    },
    instagramIntegration() {
      let integrations = this.$store.state.site.toolIntegrations;
      if (integrations) return integrations.find((i) => i.type === "instagram");
      return null;
    },
  },
  watch: {
    selectedVideo(newValue) {
      if (this.defaultCampaignObject)
        this.defaultCampaignObject.videos[0].url = newValue?.videoUrl;
    },
  },

  async created() {
    this.isLoading = true;
    await this.$store.dispatch("plan/getPlan", {
      siteId: this.$store.state.account.user.siteId,
    });
    await this.$store.dispatch("campaign/getCampaigns", {
      siteId: this.$store.state.account.user.siteId,
    });
    await this.$store.dispatch("video/getVideoLibrary", {
      siteId: this.$store.state.account.user.siteId,
      query: {
        $or: [{ isPremade: { $exists: false } }, { isPremade: false }],
        $and: [
          {
            fileName: { $exists: true },
            height: { $exists: true },
            width: { $exists: true },
            videoDuration: { $exists: true },
            url: { $not: { $regex: "blob:" } },
          },
        ],
      },
    });
    document.addEventListener("visibilitychange", () => {
      if (!document.hidden) {
        this.$store.dispatch("site/updateIntegration", {
          siteId: this.siteId,
          type: "instagram",
        });
      }
    });
    //If it's a redirection from Shopify Emebed for G Drive, display toast message
    if (this.$route.query.redirectionForGdrive) {
      this.$notify({
        title: this.$t("shared.toastMessage.useGdriveStandalone"),
        type: "success",
      });
    }

    if (this.isBulk) {
      if (this.currentFeedEdit.videos.length) {
        this.campaign = structuredClone(this.singleCampaign());
        this.files = [...this.currentFeedEdit.videos];
        this.selectedVideo = this.files[0];
      }
      this.isLoading = false;
      return;
    }
    // reset upload progress tracker
    if (this.isEditMode) {
      await this.$store.commit("video/resetVideos");
      if (Object.keys(this.singleCampaign() ?? {}).length > 0) {
        this.campaign = structuredClone(this.singleCampaign());
      } else {
        await this.$store.dispatch("campaign/getSingle", {
          campaignId: this.$route.params.campaignId,
          siteId: this.getSiteId,
        });
        this.campaign = structuredClone(
          this.$store.state.campaign.singleCampaign
        );
      }
      if (this.campaign) {
        let firstVideo = this.campaign.videos[0];
        this.files = this.campaign.videos;
        if (firstVideo._id) this.selectedVideo = firstVideo;
      } else {
        return this.$router.push({ name: "campaignManager" });
      }
    } else {
      this.userGoal = this.$route.query.goal;

      if (this.getVideos && this.getVideos.length > 0) {
        this.files = structuredClone(this.getVideos);
        this.selectedVideo = this.getVideos[0];
      }
    }
    this.isLoading = false;
  },

  methods: {
    vidjetShow(campaignId) {
      Vidjet.show(campaignId, true);
    },
    async init() {
      //Return to step one if no query parameters
      if (!this.isEditMode && !this.$route.query.goal) {
        return this.$router.push({ name: "campaignManager" });
      }
    },
    backToCampaignManager() {
      this.$store.commit("video/resetVideos");
      if (this.isEditMode) {
        this.$router.push({
          name: "campaignManager",
          params: { campaignId: this.campaign._id },
        });
      } else {
        this.$router.push({ name: "campaignManager" });
      }
    },

    async choosePremadeVideo(premadeVideo) {
      if (
        this.files.length &&
        this.numberOfVideosInLibrary + this.files.length >
          this.videosInCampaignsLimit
      ) {
        return this.showPlanRegistration();
      }
      const res = await api.getVideos({
        siteId: this.getSiteId,
        query: {
          $and: [
            { isPremade: true },
            { fileName: premadeVideo.videoObject.fileName },
          ],
        },
      });
      if (res.length > 0) {
        // we only take videoId in case video was uploaded without thumbnail
        const video = res[0];
        if (!video.thumbnail) {
          video.thumbnail = premadeVideo.videoObject.thumbnail;
          // update video with thumbnail if it was missing
          api.updateVideo(video._id, {
            thumbnail: premadeVideo.videoObject.thumbnail,
            thumbnailS3Path: premadeVideo.videoObject.thumbnailS3Path,
          });
        }
        this.files.push(video);
        this.selectedVideo = premadeVideo.videoObject;
        return;
      }
      this.files.push({
        isPremade: true,
        name: premadeVideo.name[this.i18nLocale],
        ...premadeVideo.videoObject,
      });
      this.selectedVideo = premadeVideo.videoObject;
    },

    async onFileChanged(payload) {
      let videosToUpload;
      if (payload.type === "drop") {
        videosToUpload = Array.from(payload.dataTransfer.files);
      } else if (payload.type === "quick-import") {
        videosToUpload = [payload.file];
      } else {
        videosToUpload = Array.from(payload.target.files);
      }

      //If we reach the number of videos used make sure to prevent and display upgrade modal
      if (
        videosToUpload?.length &&
        this.numberOfVideosInLibrary + videosToUpload.length >
          this.videosInCampaignsLimit
      ) {
        return this.showPlanRegistration();
      }
      this.isLoading = true;

      videosToUpload.forEach(async (videoToUpload) => {
        const loadedmetadataEvent = await this.processToVideoCheck(
          videoToUpload
        );
        if (!loadedmetadataEvent) return;
        // generate video object
        const videoMetadata = this.addMetadata(loadedmetadataEvent);
        let thumbnail = await generateVideoCover(
          URL.createObjectURL(videoToUpload),
          4
        );
        thumbnail = URL.createObjectURL(thumbnail);

        let fileName = videoToUpload.name;
        let count = 1;
        while (this.fileNameExists(fileName)) {
          fileName = this.getUniqueFileName(videoToUpload.name, count);
          count++;
        }
        videoToUpload = {
          name: fileName,
          videoToUpload: videoToUpload,
          url: URL.createObjectURL(videoToUpload),
          thumbnail,
        };
        this.files.push({
          ...videoMetadata,
          ...videoToUpload,
        });
        this.selectedVideo = this.files[this.files.length - 1];
      });
      this.isLoading = false;
    },

    fileNameExists(fileName) {
      return this.getVideoLibrary.some((video) => video.fileName === fileName);
    },

    getUniqueFileName(fileName, count) {
      const extensionIndex = fileName.lastIndexOf(".");
      if (extensionIndex !== -1) {
        return `${fileName.slice(0, extensionIndex)} (${count})${fileName.slice(
          extensionIndex
        )}`;
      } else {
        return `${fileName} (${count})`;
      }
    },

    async processToVideoCheck(video) {
      if (!this.checkFileType(video.name)) {
        return;
      }
      this.checkFileSize(video.size);
      if (!this.isFileTooBig) {
        this.isFileTooBig = false;
        const res = await this.isBrowserCompatible(video);
        !res && this.togglePlayErrorModal();
        return res;
      }
    },

    async isBrowserCompatible(file) {
      let video = document.createElement("video");
      video.setAttribute("src", window.URL.createObjectURL(file));
      var playPromise = video.play();
      video.muted = true;
      this.videoMetadataLoading = true;
      // wait for metadata to load to return a value
      const retVal = await new Promise((resolve) => {
        video.addEventListener("loadedmetadata", async (event) => {
          if (event.target.videoHeight === 0) {
            await playPromise.catch(() => {
              this.sendCodecsLog(file);
            });
            resolve(false);
          } else {
            video.pause();
            const target =
              event.target || event.currentTarget || event.originalTarget;
            resolve(target);
          }
        });
      });
      return retVal;
    },

    addMetadata(event) {
      const { videoHeight, videoWidth, duration } = event;
      this.videoMetadataLoading = false;
      return {
        height: videoHeight,
        width: videoWidth,
        videoDuration: duration,
      };
    },

    checkFileSize(size) {
      const sizeInMB = size / (1024 * 1024);
      if (
        this.currentPlan === "vidjet_tier3" &&
        sizeInMB > this.appsumoMaxFileSize
      ) {
        this.isFileTooBig = true;
        this.resetVideo();
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("shared.toastMessage.appsumoYourFileExceeds"),
          type: "error",
        });
        if (sizeInMB > 300) {
          this.isFileTooBig = true;
          this.resetVideo();
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: this.$t("shared.toastMessage.yourFileExceeds"),
            type: "error",
          });
          return;
        }

        return;
      }
    },

    async sendCodecsLog(file) {
      let result = await this.retrieveCodecs(file);
      api.sendLog({
        logMsg: `Error uploading video for site ${this.siteId} with file ${file.name} and codecs: ${result}`,
      });
    },

    async retrieveCodecs(file) {
      let result = [];
      const MP4Box = require("mp4box");
      await new Promise((resolve, reject) => {
        const mp4boxFile = MP4Box.createFile();
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = function (e) {
          const arrayBuffer = e.target.result;
          arrayBuffer.fileStart = 0;
          mp4boxFile.appendBuffer(arrayBuffer);
        };
        mp4boxFile.onReady = function (info) {
          info.tracks.forEach((track) => {
            result.push(track.codec);
          });
          resolve(mp4boxFile);
        };
        mp4boxFile.onError = function (info) {
          reject(info);
        };
      });
      return result;
    },

    checkFileType(fileName) {
      if (!this.validateFileType(fileName)) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("create.step2.alerts.supportedFormats"),
          type: "error",
        });
        return false;
      }
      return true;
    },

    validateFileType(fileName) {
      const videoFormatsRegex = /(.mov|.mp4|.webm|.ogg)$/i;

      return videoFormatsRegex.test(fileName);
    },

    resetVideo() {
      this.files = [];
      this.selectedVideo = null;
    },

    // change this method (do we even need it)
    setCampaignVideoObject(file) {
      const videoData = {
        useCase: this.$route.query.goal,
        videoDuration: Number(file.videoDuration.toFixed(1)),
        height: file.height,
        width: file.width,
        isPremade: file.isPremade,
        _id: file._id,
      };
      if (file.isPremade || file._id) {
        videoData.url = file.url;
        videoData.fileName = file.name;
        videoData.streamingUrl = file.streamingUrl;
        videoData.thumbnail = file.thumbnail;
      } else {
        videoData.tempFile = file.videoToUpload;
        videoData.fileName = file.name;
        videoData.url = file.url;
        videoData.thumbnail = file.thumbnail;
      }
      return videoData;
    },

    async goPreviousStep() {
      await this.$store.commit("video/resetVideos");
      this.$router.push({ name: "createCampaignStep1" });
    },

    async goNextStep() {
      this.isLoading = true;
      if (!this.hasVideoSet || this.files.length === 0) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("create.step2.alerts.chooseVideo"),
          type: "error",
        });
        this.isLoading = false;
        return;
      }
      this.getVideos.length > 0 && this.checkIfVideosAreAlreadyUploaded();
      if (this.files.length > 0) {
        const dataToSend = [];
        this.files.forEach((file) => {
          if (file._id && !file.isPremade) {
            dataToSend.push(file);
          } else {
            dataToSend.push(this.setCampaignVideoObject(file));
          }
        });
        this.persistVideos(dataToSend);
      }
      this.isLoading = false;
      this.$router.push({
        name: "createCampaignStep3",
        query: this.$route.query,
      });
    },

    async persistVideos(dataToSend) {
      const dataToUpdate = {
        campaignName: this.isPremade
          ? this.premadeVideo.name[this.i18nLocale]
          : this.name[this.i18nLocale],
      };

      this.$store.commit("campaign/campaignCreationInit", {
        dataToUpdate,
      });
      dataToSend.forEach(async (file, i) => {
        await this.createVideo(file, i);
      });
      this.$store.commit("campaign/campaignCreationInit", {
        dataToUpdate,
      });
      this.$store.commit("campaign/campaignCreationSetVideos", this.getVideos);
    },

    async createVideo(file, index) {
      return await this.$store.dispatch("video/createVideo", {
        file: {
          siteId: this.getSiteId,
          ...file,
        },
        index,
      });
    },

    checkIfVideosAreAlreadyUploaded() {
      let videosIds = this.getVideos.map((video) => video._id);
      this.files = this.files.filter((file) => !videosIds.includes(file._id));
    },

    async editSave() {
      if (this.numberOfVideosToUpload > 0) {
        this.showUploadProgress = true;
      } else {
        this.isLoading = true;
      }
      if (this.files.length === 0) {
        this.$notify({
          title: this.$t("shared.toastMessage.error"),
          text: this.$t("create.step2.alerts.chooseVideo"),
          type: "error",
        });
        this.isLoading = false;

        return;
      }
      let lastThumbnailGrouping = null;
      for (let i = this.files.length - 1; i >= 0; i--) {
        const video = this.files[i];
        if (video.thumbnailGrouping) {
          lastThumbnailGrouping = video.thumbnailGrouping;
          break;
        }
      }
      if (lastThumbnailGrouping) {
        for (let i = 0; i < this.files.length; i++) {
          const video = this.files[i];
          if (!video.thumbnailGrouping) {
            video.thumbnailGrouping = lastThumbnailGrouping;
            this.$store.dispatch("video/updateVideo", {
              video: video,
            });
          }
        }
      }
      if (!this.isBulk) {
        this.$store.commit("campaign/UPDATE_SINGLE_CAMPAIGN_VIDEO", {
          ...this.campaign,
          videos: this.files,
        });

        let videos = await this.getNewVideoIds();
        await this.$store.dispatch("campaign/updateCampaign", {
          campaignId: this.campaign._id,
          dataToUpdate: {
            videos,
          },
        });
        this.numberOfVideosToUpload === 0 && this.editRedirect();
      } else {
        this.$store.commit("campaign/setCurrentFeedEdit", {
          ...this.currentFeedEdit,
          videos: this.files,
        });

        let videos = await this.getNewVideoIds();

        const updatedFeedEdit = { ...this.currentFeedEdit };
        updatedFeedEdit.videos = this.files;
        this.$store.commit("campaign/setCurrentFeedEdit", updatedFeedEdit);

        this.$emit("save-videos");
      }
    },

    async getNewVideoIds() {
      let videoIds = [];
      let currentVideos = this.isBulk
        ? this.currentFeedEdit.videos
        : this.campaign.videos;
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        let video = file;
        let index =
          file._id && currentVideos.findIndex((video) => video._id == file._id);
        if (index > -1) {
          video = currentVideos[index];
        } else if (!file._id || file.isPremade) {
          video = await this.createVideo(this.setCampaignVideoObject(file), i);
          currentVideos[i]._id = video._id;
        }
        videoIds.push({ _id: video._id });
        if (video.cta) videoIds[i].cta = video.cta;
        if (video.textOptions) videoIds[i].textOptions = video.textOptions;
        if (video.thumbnailGrouping)
          videoIds[i].thumbnailGrouping = video.thumbnailGrouping;
        if (video.isMainGrouping)
          videoIds[i].isMainGrouping = video.isMainGrouping;
      }
      return videoIds;
    },
    editRedirect() {
      this.showUploadProgress = false;
      if (this.isEmbed) {
        this.$notify({
          title: this.$t("shared.toastMessage.success"),
          text: this.$t("shared.toastMessage.campaignUpdatedCopyCode"),
          type: "success",
        });
        this.$router.push({
          name: "embedStep4",
          params: { campaignId: this.campaign._id },
        });
      } else {
        this.$router.push({ name: "editCampaign" });
        this.$notify({
          title: this.$t("shared.toastMessage.success"),
          text: this.$t("shared.toastMessage.campaignUpdated"),
          type: "success",
        });
      }
    },
    togglePlayErrorModal() {
      this.showModal = !this.showModal;
    },
    async toggleImporterModal(importer) {
      if (
        this.files.length &&
        this.numberOfVideosInLibrary + this.files.length >
          this.videosInCampaignsLimit
      ) {
        return this.showPlanRegistration();
      }
      if (importer === "tiktok") {
        window.open("https://ssstik.io/en", "_blank", "noreferrer");
        return;
      } else if (importer === "instagram" && !this.instagramIntegration) {
        await this.$store.dispatch("site/getIntegration", {
          siteId: this.siteId,
          type: "instagram",
        });
      }
      if (importer === "instagram-disabled") {
        window.open(
          "https://snapinsta.app/instagram-reels-video-download?ref=Vidjet",
          "_blank",
          "noreferrer"
        );
        return;
      }
      this.showImporterModal = !this.showImporterModal;
      this.showImporterModal && (this.importUrl = "");
      this.selectedImporter = importer;
    },
    async importVideo(importer) {
      if (this.isValidUrl(this.importUrl)) {
        this.toggleImporterModal();
        this.isLoading = true;
        // Is this try catch necessary?
        try {
          const result = await api.getVideoImport(this.importUrl, importer);
          const dataUrl = Buffer.from(result.body, "base64");
          this.importMetadata = result.metadata;
          const file = new File(
            [dataUrl],
            this.importMetadata.name
              ? this.importMetadata.name + ".mp4"
              : "doc.mp4",
            { type: "video/mp4" }
          );
          var payload = {
            file,
            type: "quick-import",
          };
          this.isLoading = false;
          this.onFileChanged(payload);
        } catch (err) {
          this.isLoading = false;
          const errorText = err.text
            ? err.text
            : this.$t("create.step2.quickImportError");
          this.$notify({
            title: this.$t("shared.toastMessage.error"),
            text: errorText,
            type: "error",
          });
        }
      }
    },
    isValidUrl(string) {
      let url;
      try {
        url = new URL(string);
      } catch (_) {
        return false;
      }
      return url.protocol === "http:" || url.protocol === "https:";
    },

    removeVideo(index) {
      this.$store.commit("video/deleteVideo", index);

      this.files.splice(index, 1);
      if (this.files.length === 0) {
        this.resetSelectedVideo();
      } else {
        this.selectedVideo = this.files[index > 0 ? index - 1 : 0];
      }
    },
    reorderVideo(carousel) {
      this.$store.commit("video/setVideos", carousel);
      this.files = [...carousel];
    },

    closeLibraryModal() {
      if (this.files.length === 0) {
        this.resetSelectedVideo();
      } else {
        this.selectedVideo = this.files[this.files.length - 1];
      }
      this.openVideoLibrary = false;
    },

    selectVideoInPreview(video) {
      this.selectedVideo = video;
    },

    async importVideosFromLibrary(videoArray) {
      //With the logic change with the limit being on video library,
      //We let the user add videos to campaigns without needing to check the limit

      videoArray.forEach(async (videoObject) => {
        if (!videoObject.thumbnail) {
          videoObject.thumbnail = await generateVideoCover(videoObject.url, 4);
          videoObject.thumbnail = URL.createObjectURL(videoObject.thumbnail);
        }
        this.isImageReturningAnError(videoObject.thumbnail).then(
          async (result) => {
            if (!result) {
              const blobFile = await generateVideoCover(videoObject.url, 4);
              videoObject.thumbnail = URL.createObjectURL(blobFile);
              return;
            }
          }
        );
        this.files.push(videoObject);
      });
      this.selectedVideo = videoArray[videoArray.length - 1];
      this.openVideoLibrary = false;
    },
    onInstagramImport(files) {
      files.forEach((file) => {
        var payload = {
          file,
          type: "quick-import",
        };
        this.onFileChanged(payload);
      });
      this.toggleImporterModal();
    },
    isImageReturningAnError(thumbnail) {
      return new Promise((resolve) => {
        let img = new Image();
        img.onload = () => {
          resolve(true);
        };
        img.onerror = () => {
          resolve(false);
        };
        img.src = thumbnail;
      });
    },
    async disconnectInstagram() {
      const payload = {
        siteId: this.siteId,
        _id: this.instagramIntegration._id,
      };
      await this.$store.dispatch("site/deleteIntegration", payload);
      this.showImporterModal = !this.showImporterModal;
    },
    removeVideos() {
      this.files = [];
      this.resetSelectedVideo();
      this.toggleImporterModal();
    },
    closeVideoModal() {
      this.files = [];
      this.$emit("close-videos-modal");
    },
    async showPlanRegistration() {
      if (this.currentPlan === "pro") {
        window.Intercom &&
          window.Intercom("trackEvent", "plan_enterprise_clicked");
        this.notifyTooManyVideos();
        return;
      }
      this.proposedPlan = await this.$store.dispatch("plan/getProposedPlan", {
        siteId: this.siteId,
      });
      this.showPlanRegistrationModal = true;
      this.notifyTooManyVideos();
    },
    notifyTooManyVideos() {
      this.$notify({
        text: this.$t("shared.toastMessage.tooManyVideos"),
        type: "error",
      });
    },
    resetSelectedVideo() {
      this.selectedVideo = {
        height: 400,
        width: 200,
        duration: 15,
        isPremade: false,
        url: "https://media.vidjet.io/New+default+preview.mp4",
        thumbnail: require("../../assets/new-preview-thumbnail.png"),
      };
    },
  },
};
</script>

<style lang="scss">
.video-library-modal .sliding-modal {
  min-width: 408px;
  padding: 20px 0;
  margin-left: 0;
}
</style>

<style lang="scss" scoped>
$pink-box-shadow: 0px 0px 10px rgba(123, 1, 181, 0.25);
.hidden {
  display: none;
}

.disabled {
  color: $light-grey;
  cursor: not-allowed;
}

.v-tooltip-button {
  background: transparent;
  margin-left: 55px;
  margin-top: 40px;
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.step-2-wrapper {
  height: 100vh;
  display: flex;
}

.close-icon {
  position: absolute;
  background: none;
  cursor: pointer;
  z-index: map-get($zindex, overlay);
  right: $close-icon-margin;
  top: $close-icon-margin;
}

.video-upload {
  overflow: auto;
  width: 25%;
  display: flex;
  flex-direction: column;
  place-items: center;
  gap: 16px;
  padding-top: 24px;
  justify-content: initial;
}

.bulk-video-upload {
  padding-top: 0;
}

.video-upload::-webkit-scrollbar {
  width: 0;
}

.video-upload::-webkit-scrollbar-track {
  background-color: transparent;
}

.video-upload::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.premade-video-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  text-align: center;
  width: 90%;
}

.premade-video-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, 70px);
  max-width: 400px;
  justify-content: center;
  grid-gap: 32px;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 16px;
  }
}

.step-title {
  display: flex;
  flex-direction: column;
  @include font-big;
  text-align: center;
  .bold {
    margin-left: 8px;
    font-weight: $w-bolder;
  }
}

.upload-video-area {
  cursor: pointer;
  border-radius: $card-border-radius;
  border: 2px dashed $dark-purple;
  width: 90%;
  height: 100%;
  min-height: 100px;
  @include flex-centered;
  flex-direction: column;
  .flex-centered {
    flex-direction: column;
  }
}

.quick-import-area {
  width: 90%;
  box-shadow: 0px 0px 10px rgba(123, 1, 181, 0.25);
  border-radius: 11px;
  text-align: center;
  font-size: 14px;
  padding: 16px 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .quick-import-button {
    text-align: center;
  }
}
.bulk-quick-import-area {
  padding: 16px 24px;
}

.quick-import-heading {
  margin-bottom: 16px;
  @include font-small;
  font-weight: 600;
}

.quick-import-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: start;
  gap: 8px;
  margin-top: 8px;
}
.bulk-quick-import-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.quick-import-button {
  @include base-font;
  @include font-smaller;
  border-radius: 4px;
  background-color: white;
  padding: 0 5px;
  height: 30px;
  display: flex;
  align-content: center;
  align-items: center;
  gap: 8px;
  margin: 0px auto;
  width: 100%;
  box-sizing: content-box;
  &:hover {
    background: $light-pink;
  }
  img {
    vertical-align: middle;
  }
  .youtube-logo {
    height: 30px;
  }
  .open-new-link {
    transform: scale(0.8);
    path {
      fill: #585858;
    }
  }
}
.youtube-importer-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}
.youtube-modal-title {
  @include base-font;
  @include font-big;
  font-weight: 600;
}
.youtube-logo-big {
  width: 33px;
  display: inline;
}

.upload-button {
  font-family: "Montserrat", sans-serif;
  background: $dark-purple;
  color: white;
  @include font-small;
  padding: 2px 12px;
  border-radius: 5px;
  margin-right: 6px;
  margin-bottom: 4px;
  font-weight: $w-bold;
}

.upload-drop-text {
  @include font-small;
  font-weight: $w-normal;
}

.upload-formats {
  margin-top: 16px;
  @include font-smallest;
  font-weight: $w-light;
}

.premade-video-block {
  @include flex-centered;
  flex-direction: column;
  justify-content: space-evenly;
  width: 90%;
  border-radius: $card-border-radius;
  box-shadow: $pink-box-shadow;
}
.bulk-premade-video-block {
  margin-bottom: 120px;
}

.premade-video-block-title {
  margin: 16px auto;
  @include font-small;
  font-weight: $w-bold;
  svg {
    stroke: $dark-grey;
  }
}

.embed-video-container {
  border-radius: $card-border-radius;
  box-shadow: $pink-box-shadow;
  width: 90%;
}
.card-video {
  cursor: pointer;
  position: relative;
  width: 70px;
  height: 70px;
  border-radius: $card-border-radius;
  text-align: center;
  margin-bottom: 8px;
  margin: 0 auto 8px auto;
}

.video-image {
  box-shadow: inset 0px 4px 0px 100px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(3px 3px 0px $nice-pink) brightness(80%);
  height: 70px;
  width: inherit;
  border-radius: 5px;
}

.campaign-type {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
  @include font-smaller;
  font-weight: $w-bold;
  color: white;
  width: 90%;
  overflow-wrap: break-word;
}

.see-example {
  @include font-smallest;
  cursor: pointer;
  text-decoration: underline;
}

.need-more {
  box-shadow: 3px 3px 0px $light-purple;
  width: 70px;
  height: 70px;
  text-align: center;
  background: $light-pink;
  display: flex;
  align-items: center;
  border: radius;
  border-radius: $card-border-radius;
  margin-bottom: 8px;
  margin: 0 auto 8px auto;
  p {
    color: $dark-grey;
    font-weight: $w-bold;
  }
}

.img-container {
  display: flex;
  padding: 10px 5px;
  justify-content: space-between;
}

.actor-image {
  cursor: pointer;
  border-radius: 5px;
  height: 70px;
  width: 70px;
}

.settings-footer-container {
  width: 100%;
  position: sticky;
  bottom: 0;
  height: 64px;
  background: white;
}

.settings-footer-block {
  margin: 8px auto 8px auto;
  width: 90%;
}

.edit-footer {
  margin: 8px auto 8px auto;
  width: 100%;
}

.preview-wrapper {
  height: 100%;
  width: 75%;
  display: grid;
  grid-template-rows: 1fr minmax(0, auto);
  background: $light-pink;
  padding: 0;
}
.bulk-preview-wrapper {
  width: 75%;
  height: 90%;
}

.importer-modal-title {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  text-align: center;
  margin: 12px auto 24px;
}

.importer-form {
  margin: 10px;
}

.text-input-content {
  min-width: 300px;
}

.input-importer {
  min-width: 300px;
  border-radius: 3px;
  outline: none;
  border: 1px solid grey;
}

.input-importer-content {
  border: 1.5px solid rgba(226, 124, 252, 0.7);
}
.modal-background {
  z-index: map-get($zindex, modal);
  width: 45%;
  background-color: transparent;
}
</style>
