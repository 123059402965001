<template>
  <div v-click-outside="closeOptionsModal" class="campaign-options-modal">
    <h1 class="options-title">{{ $t("campaignManager.options") }}</h1>
    <div class="options">
      <button class="option-button" @click="emitEditCampaign">
        <EditIcon class="edit-icon" />
        {{ $t("campaignManager.editCampaign") }}
      </button>
      <button class="option-button" @click="emitCloneCampaign">
        <CloneIcon class="icon" />
        {{ $t("campaignManager.cloneCampaign") }}
      </button>
      <button class="option-button" @click="emitDeleteCampaign">
        <DeleteIcon class="icon" />
        {{ $t("campaignManager.deleteCampaign") }}
      </button>
      <button
        v-if="
          campaign.formats[0].formatType === 2 ||
          campaign.formats[0].formatType === 3
        "
        class="option-button"
        @click="emitGetEmbedCode"
      >
        <CodeIcon class="icon" />
        {{ $t("create.bulk.headerButtons.embedCode") }}
      </button>
      <button v-if="isVideoViewsLoaded" class="option-button" @click="showUrls">
        <OpenIcon class="icon" />
        {{ $t("campaignManager.associatedUrls") }}
      </button>
      <button
        v-else
        v-tippy="{ placement: 'top', arrow: true }"
        class="disabled-button"
        :content="$t('campaignManager.sortOptions.listNotReady')"
      >
        <OpenIcon class="icon" />
        {{ $t("campaignManager.associatedUrls") }}
      </button>
    </div>
  </div>
</template>

<script>
import OpenIcon from "@/assets/svg/cta/open-icon.svg?inline";
import DeleteIcon from "@/assets/svg/delete.svg?inline";
import CodeIcon from "@/assets/svg/code-icon.svg?inline";
import EditIcon from "@/assets/svg/edit-icon.svg?inline";
import CloneIcon from "@/assets/svg/clone-icon.svg?inline";

export default {
  components: {
    OpenIcon,
    DeleteIcon,
    CodeIcon,
    EditIcon,
    CloneIcon,
  },
  props: {
    campaign: {
      type: Object,
      required: true,
    },
    isEmbed: {
      type: Boolean,
      required: true,
    },
    isVideoViewsLoaded: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    emitEditCampaign() {
      this.$emit("edit-campaign", this.campaign);
    },
    emitCloneCampaign() {
      this.$emit("clone-campaign", this.campaign);
    },
    emitDeleteCampaign() {
      this.$emit("open-delete-modal", this.campaign._id);
    },
    showUrls() {
      this.$emit("show-urls", this.campaign);
    },
    closeOptionsModal() {
      this.$emit("close-options-modal");
    },
    emitGetEmbedCode() {
      this.$emit("get-embed-code", this.campaign);
    },
  },
};
</script>
<style lang="scss" scoped>
.campaign-options-modal {
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.25);
  padding: 16px;
  width: 200px;
  min-height: 185px;
  z-index: 99;
  background: white;
  position: absolute;
  top: -136px;
  right: 64px;
  border-radius: 4px;
}
.options-title {
  @include base-font;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  color: $light-grey;
  margin-bottom: 16px;
}
.options {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
}
.option-button {
  @include base-font;
  background: transparent;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: $dark-grey;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  position: relative;
  text-align: left;
  &:hover {
    width: 100%;
    background: $dark-purple;
    color: white;
    border-radius: 4px;
    text-align: left;
    .icon path {
      fill: white;
    }
    .edit-icon path {
      stroke: white;
    }
  }
}

.disabled-button {
  @include base-font;
  background: transparent;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: $light-grey;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  position: relative;
  text-align: left;
  cursor: not-allowed;
  .icon {
    path {
      fill: $light-grey;
    }
  }
}
</style>
