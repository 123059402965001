<template>
  <small-modal
    :title="title"
    class="embed-code-modal-wrapper"
    :fit-content="true"
    @close="close"
  >
    <div class="embed-code-modal-content">
      <h2 class="subtitle">{{ $t("create.bulk.embedModalCM.subtitle") }}</h2>
      <InstructionBanner
        class="embed-code-instruction-banner"
        selected-name="leo"
        :title-text="InstructionBannerTitle"
        :link-text="InstructionBannerButton"
        :link-href="instructionBannerLink"
      />
      <div class="script-to-copy">
        <p class="iframe-code-to-copy">
          {{ getEmbedCode(campaign, siteIntegration) }}
        </p>
        <button
          v-clipboard="
            () => getEmbedCode(campaign, siteIntegration).replace(/(\r\n|\n|\r)/gm, '')
          "
          v-clipboard:success="clipboardSuccessHandler"
          v-clipboard:error="clipboardErrorHandler"
          class="action-button"
        >
          {{ $t("create.step5.copyCode") }}
        </button>
      </div>
    </div>
  </small-modal>
</template>

<script>
import SmallModal from "../shared/SmallModal";
import InstructionBanner from "@/components/shared/InstructionBanner.vue";
import getEmbedCode from "@/utils/get-embed-code.js";
import { mapGetters } from "vuex";


export default {
  components: {
    SmallModal,
    InstructionBanner,
  },
  props: {
    campaign: { type: Object, default: null },
  },
  computed: {
    ...mapGetters({
      siteIntegration: "site/siteIntegration"
    }),
  },
  data() {
    return {
      title: this.$t("create.bulk.embedModalCM.title"),
      InstructionBannerTitle: this.$t("create.bulk.embedModalCM.bannerText"),
      InstructionBannerButton: this.$t("create.bulk.embedModalCM.bannerButton"),
      targetedPlayer: "player.vidjet.io",
      instructionBannerLink:
        "https://help.vidjet.io/en/articles/6158285-how-to-embed-videos-on-your-ecommerce-platform-shopify",
      getEmbedCode,
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    clipboardSuccessHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.copied"),
        type: "success",
      });
    },
    clipboardErrorHandler() {
      this.$notify({
        title: this.$t("shared.toastMessage.failedToCopy"),
        type: "error",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.subtitle {
  @include base-font;
  @include font-normal;
  font-weight: 400;
  text-align: center;
  margin-bottom: 24px;
}
.embed-code-instruction-banner {
  width: 90%;
  margin-bottom: 16px;
}

.script-to-copy {
  position: relative;
  border: 1px solid $dark-purple;
  box-sizing: border-box;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: $card-border-radius;
  margin: 0 auto;
  overflow: auto;
  padding: 16px 24px;
  display: grid;
  grid-template-columns: 1fr auto;
  place-items: center;
  width: 100%;
  background-color: white;
  @include font-smaller;
  margin-bottom: 16px;
}
.iframe-code-to-copy {
  overflow: auto;
  width: 100%;
  color: $medium-grey;
}

.action-button {
  position: absolute;
  right: 16px;
  width: unset;
}
.iframe-code-to-copy::-webkit-scrollbar {
  width: 0;
}

.iframe-code-to-copy::-webkit-scrollbar-track {
  background-color: transparent;
}

.iframe-code-to-copy::-webkit-scrollbar-thumb {
  background-color: transparent;
}
</style>
