<template>
  <main
    v-if="showPlayers"
    :class="[
      'main-wrapper',
      {
        isPopup,
        isMobile,
        isEmbed,
        isFullScreen,
        isPortrait,
        isLandscape,
        isBubble,
        isMultiple,
        isDesktop: !isMobile,
        isHorizontalSwipe: isHorizontalSwipe && isPlayerOpened,
      },
      { isCarousel: showCarousel },
    ]"
    :style="
      isMobile && !isInPreviewComponent && 'height: calc(var(--vh, 1vh) * 100);'
    "
    v-visibility-change="sendFlowDurationOnVisibilityChange"
  >
    <transition name="close-button"> </transition>
    <transition name="fade">
      <SpinnerLoader
        v-if="isLoading"
        class="spinner"
        :background="backgroundColor"
      />
    </transition>
    <div
      v-if="!showCarousel"
      :style="[isLoading ? { opacity: 0 } : '']"
      class="format-container"
    >
      <div
        class="v-slide-wrapper"
        v-slide="{
          numberOfVideos: videos.length,
          isMobile: isMobile && !isTablet,
          isInPreview: isInPreviewComponent,
          isSlidable: isSlidable,
          index: currentIndex,
          isHorizontalSwipe: isHorizontalSwipe,
        }"
        :style="
          isHorizontalSwipe &&
          `grid-template-columns: repeat(${videos.length}, 100%);`
        "
      >
        <div
          class="player-wrapper"
          v-for="(video, index) in videos"
          :key="video.id"
          :style="[borderRadiusStyle]"
        >
          <video-player
            v-if="!isBubble"
            :video-index="index"
            @notify-preview="$emit('notify-preview')"
            class="video-player"
          >
          </video-player>
          <BubbleFormat
            v-else
            ref="bubble"
            :videoIndex="index"
            @notify-preview="$emit('notify-preview')"
          />
        </div>
      </div>
    </div>
    <div v-else class="carousel-container" :style="justifyContentStyle">
      <CarouselFormat
        :is-preview="isInPreviewComponent"
        @open-full-screen="toggleFullScreenCarousel"
        @carousel-height="carouselChangeHeight"
      />
    </div>
  </main>
</template>

<script>
import enums from "@/enums.js";
import { changeIframe } from "@/utils/utils";
import api from "@/api/api.js";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/utils/eventBus";
// import MockCampaign from "@/utils/.mock-campaign.js";

const { FormatType, DeviceType } = enums;

export default {
  name: "Home",
  components: {
    SpinnerLoader: () => import("@/components/shared/SpinnerLoader.vue"),
    VideoPlayer: () => import("@/components/VideoPlayer.vue"),
    CarouselFormat: () => import("@/formats/CarouselFormat.vue"),
    BubbleFormat: () => import("@/formats/BubbleFormat.vue"),
  },
  data() {
    return {
      FormatType,
      DeviceType,
      showPlayers: false,
      hasDisplayEventBeenSent: false,
      isLoading: false,
      isDraggable: false,
      timeSpentOnFlow: 0,
      timeSpentOnFlowSent: 0,
      flowStartTime: null,
      showCarousel: false,
      clickedCarouselIndex: 0,
      carouselHeight: 0,
      widgetToDisplayHandled: false,
    };
  },

  props: {
    previewCampaign: {
      type: Object,
      default: () => {},
    },

    isPreviewDeviceMobile: {
      type: Boolean,
      default: true,
    },

    isInPreviewComponent: {
      type: Boolean,
      default: false,
    },
    siteProp: {
      type: Object,
      default: () => {},
    },
    // this prop is useful when in preview, on the video customisation page
    // a video might be alone but it belongs to a campaign with multiple videos
    hasMultipleVideos: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    previewCampaign: {
      handler(newValue) {
        if (!this.isInPreviewComponent) return;
        this.setCampaign(newValue);
        this.setIsMobile(this.isPreviewDeviceMobile);
        if (
          newValue.videos[0].thumbnailGrouping &&
          !(this.isInPreviewComponent && newValue.videos.length === 1)
        ) {
          const uniqueThumbnailGroups = this.filterUniqueThumbnailGroups(
            newValue.videos
          );
          this.setVideos(uniqueThumbnailGroups);
        } else {
          this.setVideos(newValue.videos);
        }
        this.selectFormat(this.isMobile ? "mobile" : "desktop");
        this.setHasMultipleVideos(this.hasMultipleVideos);
      },
      immediate: true,
      deep: true,
    },

    isPlayerOpened(newValue) {
      // if fullscreen is toggled we want to hide the carousel
      if (this.isCarouselFullScreen && !newValue) {
        this.toggleFullScreenCarousel();
      } else if (this.isCarouselFullScreen && newValue) {
        this.showCarousel = false;
      }
      if (!this.flowStartTime && !this.isInPreviewComponent) {
        this.hasFlowId || this.addAnalytics({ flowId: this.$uuid.v4() });
        //This call is being sent when we should not.
        // this.sendEvent({
        //   type: "enterFlow",
        // });
      }
      if (newValue) {
        // flowStartTime to anayltics store?
        this.flowStartTime = Date.now();
        this.sendVideoDisplayEvent();
        this.isDraggable = true;
      } else {
        // this.sendFlowDuration();
        this.isDraggable = false;
      }

      if (this.isInPreviewComponent && this.isFullScreen) {
        this.$emit("toggle-fullscreen", newValue);
      }
    },

    hasPlayerLoaded(newValue) {
      if (newValue) {
        this.isLoading = false;
      }
    },

    "format.formatType": {
      handler(newValue, oldValue) {
        if (newValue === oldValue || oldValue === undefined) {
          return;
        }
        if (newValue === FormatType.carousel) {
          this.showCarousel = true;
        } else {
          this.showCarousel = false;
        }
        this.togglePlayer(false);
        this.setCurrentIndex(0);
      },
      deep: true,
    },

    "videos.length": {
      handler(newValue) {
        // for preview when going in individual customisation => reset index
        if (!this.isInPreviewComponent) return;
        if (newValue === 1 && this.currentIndex > 0) {
          this.setCurrentIndex(0);
        } else if (this.currentIndex > newValue - 1) {
          this.setCurrentIndex(newValue - 1);
        }
      },
      deep: true,
    },
    // to move to the store
    currentIndex() {
      if (!this.videos?.length) return;

      if (this.currentIndex >= this.videos?.length) {
        this.setCurrentIndex(0);
      }
      // update videoId in analytics
      this.addAnalytics({ videoId: this.videos[this.currentIndex]?._id });
      this.sendVideoDisplayEvent();
    },

    isPreviewDeviceMobile(newValue) {
      this.setIsMobile(newValue);
      this.selectFormat(this.isMobile ? "mobile" : "desktop");
    },
  },

  async mounted() {
    this.setPreview(this.isInPreviewComponent);

    if (this.isInPreviewComponent) {
      this.setSite(this.siteProp);
      this.setCampaign(this.previewCampaign);

      //If we are in the preview just default to the videos of the first feed in bulk
      if (this.previewCampaign.bulk) {
        this.setVideos(this.previewCampaign.bulk[0].videos);
      }

      if (!this.isCarousel) {
        this.setCurrentIndex(0);
      }

      await this.initiatePlayerDisplay();

      return;
    }

    // TODO: extract listeners
    this.listenToMessages();

    //THis is in case the iframe refreshed itself and we want to send the videos data again
    changeIframe("newBulkLoaded", {
      campaignId: this.$route.params.campaignId,
    });

    this.setIsMobile(
      this.$route.query["device"]
        ? this.$route.query["device"] === "mobile"
        : /Mobi|Android/i.test(navigator.userAgent)
    );

    this.setAnalytics({
      route: this.$route,
      device: this.isMobile ? "mobile" : "desktop",
    });

    //If some informations are being sent directly in URL save them
    if (this.$route.query.productId) {
      this.addAnalytics({ productId: parseInt(this.$route.query.productId) });
    }

    if (this.$route.query.url) {
      this.addAnalytics({ url: this.$route.query.url });
    }

    //Send message to the script to get the page analytics
    changeIframe("getAnalytics");

    //If we add a parameters in the url call campaign. This is when we load app directly in loacalhost and not with the script
    if (
      this.$route.query.backendCall === "true" ||
      this.$route.query.backendCall === true
    ) {
      const data = await api.getCampaign({
        siteId: this.$route.params.siteId,
        campaignId: this.$route.params.campaignId,
      });
      // const data = MockCampaign;

      if (
        data.campaign &&
        data.campaign.videos &&
        data.campaign.videos[0].thumbnailGrouping
      ) {
        const uniqueThumbnailGroups = this.filterUniqueThumbnailGroups(
          data.campaign.videos
        );
        this.setVideos(uniqueThumbnailGroups);
      } else {
        this.setVideos(data.campaign.videos);
      }
      this.setCampaign(data.campaign);
      this.setSite(data.site);

      this.selectFormat(this.isMobile ? "mobile" : "desktop");

      if (this.site.isLocked) {
        this.isLoading = true;
        return;
      }
    }

    if (!this.videos?.length) {
      return;
    }

    // when carousel is closed there is no current videos
    if (!this.isCarousel) {
      this.setCurrentIndex(0);
    }

    await this.initiatePlayerDisplay();
  },

  beforeDestroy() {
    // or multiple in the future
    this.togglePlayer(false);
    if (this.isFullScreen && this.isPlayerOpened) {
      // this.sendFlowDuration();
      window.onbeforeunload = null;
    }
  },

  computed: {
    ...mapGetters("playerCampaign", [
      "campaign",
      "format",
      "isEmbed",
      "isBubble",
      "isCarousel",
      "isPopup",
      "isFullScreen",
      "isCarouselFullScreen",
      "isStory",
      "isHorizontalSwipe",
    ]),
    ...mapGetters("videos", [
      "videos",
      "isMultiple",
      "isPortrait",
      "isLandscape",
      "isLastVideo",
      "currentIndex",
    ]),
    ...mapGetters("analytics", [
      "hasFlowId",
      "getBuyNowProduct",
      "getUrl",
      "getPageProductId",
    ]),
    ...mapGetters("player", [
      "isTablet",
      "isMobile",
      "isInPreview",
      "isPlayerOpened",
      "isAtcOpened",
      "hasPlayerLoaded",
      "hasScriptLoaded",
    ]),
    ...mapGetters("playerSite", ["site"]),
    ...mapGetters("videoJS", ["placeholderUrl"]),

    isSlidable() {
      return this.isMultiple && !this.isAtcOpened;
    },

    autoScroll() {
      return this.isMultiple && this.format.autoScroll;
    },
    backgroundColor() {
      //Special background for we are circles client
      if (this.$route.params.siteId === "cddca02f-387a-4c4f-ae8f-34ab8748d5d2")
        return "#fff9eb";

      return this.isEmbed || this.isCarousel
        ? "rgb(260, 260, 260)"
        : "rgba(0, 0, 0, 0.3)";
    },
    hasThumbnailGrouping() {
      return !!this.videos[0]?.thumbnailGrouping;
    },
    justifyContentStyle() {
      //special request for https://jameshawk.pl/
      if (
        this.$route.params.campaignId === "724b9d06-6887-48e1-a3a5-0971d8159b1b"
      ) {
        return { justifyContent: "start" };
      }

      if (this.isMobile) {
        return { justifyContent: "center" };
      }

      if (this.format.position === 0) {
        return { justifyContent: "start" };
      } else if (this.format.position === 1) {
        return { justifyContent: "end" };
      } else {
        return { justifyContent: "center" }; // Default case
      }
    },
    borderRadiusStyle() {
      if (this.isFullScreen && this.isPlayerOpened && !this.isHorizontalSwipe) {
        return {};
      } else if (Number.isInteger(this.format.cornerShape)) {
        let borderRadius = this.format.cornerShape.toString() + "px";
        return { borderRadius: borderRadius };
      } else {
        return { borderRadius: "10px" };
      }
    },
  },
  methods: {
    ...mapMutations({
      setCampaign: "playerCampaign/SET_CAMPAIGN",
      setSite: "playerSite/SET_SITE",
      setVideos: "videos/SET_VIDEOS",
      setCurrentIndex: "videos/SET_CURRENT_INDEX",
      setPreview: "player/SET_PREVIEW",
      setIsMobile: "player/SET_IS_MOBILE",
      setIsFlowOpened: "player/SET_IS_FLOW_OPENED",
      togglePlayer: "player/TOGGLE_PLAYER",
      setAnalytics: "analytics/SET_ANALYTICS",
      addAnalytics: "analytics/ADD_ANALYTICS",
      setScriptLoaded: "player/SET_SCRIPT_LOADED",
      setHasMultipleVideos: "videos/SET_HAS_MULTIPLE_VIDEOS",
    }),
    ...mapActions({
      createBlobUrl: "videoJS/createBlobUrl",
      sendEvent: "analytics/sendEvent",
      selectFormat: "playerCampaign/selectFormat",
      reorganizeVideos: "videos/reorganizeVideos",
    }),

    async initiatePlayerDisplay() {
      // important for the source urls
      this.isCarousel && this.setCurrentIndex(0);

      if (!this.isInPreviewComponent) {
        // add first video as default id
        this.addAnalytics({
          videoId: this.videos[0]?._id,
        });
      }

      if (this.isMultiple || this.isCarousel || this.isEmbed) {
        await this.createBlobUrl();
      }

      // for these formats player is open by default
      if (
        this.isPopup ||
        this.isEmbed ||
        (this.isCarousel && !this.isFullScreen)
      ) {
        this.togglePlayer(true);
      }

      if (this.isMultiple && !this.format.isFullScreen) this.isDraggable = true;

      this.showCarousel = this.isCarousel;

      this.showPlayers = true;
      this.isLoading = false;
    },

    listenToMessages() {
      window.addEventListener(
        "message",
        async (event) => {
          if (event.data?.action === "sendCartItems") {
            const url = await this.sendEventAndGetUrl(event);
            if (url ?? false) {
              window.top.location.href = url.checkoutUrl;
            } else {
              window.top.location.href = document.referrer + "/cart";
            }
          }

          if (event.data?.action === "getAnalytics") {
            this.addAnalytics(event.data.data);
            this.setScriptLoaded(true);

            if (this.carouselHeight > 0 && !this.isStory) {
              changeIframe("carouselHeight", {
                carouselHeight: this.carouselHeight,
                campaignId: this.campaign._id,
              });
            }

            if (!this.videos?.length) {
              return;
            }

            if (
              !this.isInPreviewComponent &&
              !this.hasDisplayEventBeenSent &&
              !this.isEmbed
            ) {
              this.hasDisplayEventBeenSent = true;
              this.sendEvent({
                type: "display",
                videoId: this.videos[0]._id,
              });
            }
          }

          if (event.data?.action === "scriptCloseEvent") {
            EventBus.$emit("script-close-event");
          }

          if (event.data?.action === "widgetToDisplay") {
            const { campaign, site } = event.data.data;

            //If it was already sent or if the bakcend call is set, no need to go further
            if (this.$route.query.backendCall || this.widgetToDisplayHandled)
              return;

            this.widgetToDisplayHandled = true;

            this.setCampaign(campaign);
            this.setSite(site);

            if (campaign.videos[0]?.thumbnailGrouping) {
              this.setVideos(this.filterUniqueThumbnailGroups(campaign.videos));
            } else {
              this.setVideos(campaign.videos);
            }

            this.selectFormat(this.isMobile ? "mobile" : "desktop");

            if (!this.videos?.length) {
              return;
            }

            // when carousel is closed there is no current videos
            if (!this.isCarousel) {
              this.setCurrentIndex(0);
            }

            await this.initiatePlayerDisplay();

            if (this.carouselHeight > 0 && !this.isStory) {
              changeIframe("carouselHeight", {
                carouselHeight: this.carouselHeight,
                campaignId: this.campaign._id,
              });
            }

            if (
              !this.isInPreviewComponent &&
              !this.hasDisplayEventBeenSent &&
              !this.isEmbed
            ) {
              this.hasDisplayEventBeenSent = true;
              this.sendEvent({
                type: "display",
                videoId: this.videos[0]._id,
              });
            }
          }
        },
        false
      );
    },

    sendEventAndGetUrl(event) {
      let items = event.data.data;
      items.push(this.getBuyNowProduct);
      if (!items || items.length === 0) return;
      // delete productId from item in items
      items = items.map((item) => {
        delete item.productId;
        return item;
      });
      let payload = {
        siteId: this.$route.params.siteId,
        items,
      };
      const url = api.checkout(payload);
      return url;
    },
    // move to analytics store
    sendFlowDuration(event, hidden) {
      this.timeSpentOnFlow += (Date.now() - this.flowStartTime) / 1000;
      if (
        !this.isInPreview &&
        this.timeSpentOnFlow > this.timeSpentOnFlowSent + 1
      )
        this.sendEvent({
          type: "timeSpentOnFlow",
          flowViewTime: this.timeSpentOnFlow,
        });
      if (hidden) {
        this.flowStartTime = null;
      } else if (!hidden && this.isPlayerOpened) {
        this.flowStartTime = Date.now();
      }
      this.timeSpentOnFlowSent = this.timeSpentOnFlow;
    },

    sendVideoDisplayEvent() {
      if (!this.isInPreview && !this.showCarousel) {
        this.sendEvent({
          type: "videoDisplay",
        });
      }
    },

    async toggleFullScreenCarousel(payload = null) {
      if (payload !== null) {
        !this.isStory && (this.isLoading = true);
        this.showLoadingScreen();
        this.clickedCarouselIndex = payload;
        if (this.hasThumbnailGrouping) {
          await this.handleThumbnailGrouping();
        }
      } else if (payload === null && this.hasThumbnailGrouping) {
        await this.handleThumbnailGrouping(true);
      } else if (this.clickedCarouselIndex > 0) {
        this.clickedCarouselIndex =
          this.videos.length - this.clickedCarouselIndex;
      }
      this.togglePlayer(this.showCarousel);
      this.showCarousel = !this.showCarousel;
      this.setCurrentIndex(0);
      // Skip reorganize when closing FS and thumbnail grouping
      if (!(payload === null && this.hasThumbnailGrouping)) {
        // Reorganize array the video clicked is first
        await this.reorganizeVideos({
          index: this.clickedCarouselIndex,
          videos: this.videos,
        });
      }
      this.$forceUpdate();
      // Add videoId to send to analytics
      if (!this.showCarousel && !this.isInPreview) {
        this.sendEvent({
          type: "carouselClick",
        });
      }
    },

    showLoadingScreen() {
      const intervalId = setInterval(() => {
        // No script in development mode
        const regex = /\b(192\.\d{1,3}\.\d{1,3}\.\d{1,3}|localhost)\b/;

        if (
          this.hasScriptLoaded ||
          regex.test(window.location.origin) ||
          this.$route.params.siteId === "bb82b465-34ca-46b1-bc68-b1c8b826dead"
        ) {
          changeIframe("openFullScreen", this.$route.params.campaignId);
          clearInterval(intervalId);
          setTimeout(() => (this.isLoading = false), 200);
        }
      }, 200);
    },

    async handleThumbnailGrouping(resetVideos = false) {
      if (resetVideos) {
        const uniqueThumbnailGroups = this.filterUniqueThumbnailGroups(
          this.campaign.videos
        );
        this.setVideos(uniqueThumbnailGroups);
      } else {
        const clickedVideoId = this.videos[this.clickedCarouselIndex]?.videoId;
        if (clickedVideoId) {
          let videosToSet = this.campaign.videos;
          const clickedIndexInVideos = videosToSet.findIndex((video) => {
            return video.videoId === clickedVideoId;
          });
          if (clickedIndexInVideos !== -1) {
            this.clickedCarouselIndex = clickedIndexInVideos;
          }
          this.setVideos(videosToSet);
        }
      }
    },

    carouselChangeHeight(payload) {
      this.carouselHeight = payload;
      if (this.carouselHeight > 0 && !this.isStory) {
        changeIframe("carouselHeight", {
          carouselHeight: this.carouselHeight,
          campaignId: this.campaign._id,
        });
      }
    },

    sendFlowDurationOnVisibilityChange(e, hidden) {
      if (this.isPlayerOpened && hidden) {
        // this.sendFlowDuration();
        this.flowStartTime = null;
      } else if (this.isPlayerOpened && !hidden) {
        this.flowStartTime = Date.now();
      }
    },

    sendFlowDurationOnLeave() {
      window.onbeforeunload = () => {
        // this.isPlayerOpened && this.sendFlowDuration();
      };
    },

    filterUniqueThumbnailGroups(videos) {
      const uniqueGroups = new Set();
      if (this.isInPreviewComponent && videos.length === 1) {
        return videos;
      }
      return videos.filter((video) => {
        if (uniqueGroups.has(video.thumbnailGrouping)) {
          return false;
        } else {
          if (video.isMainGrouping) {
            uniqueGroups.add(video.thumbnailGrouping);
            return true;
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
$player-rounded-corners: 10px;

.main-wrapper {
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.v-slide-wrapper {
  height: 100%;
  width: 100%;
  // overflow: hidden;
}

.player-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.isFullScreen {
  .player-wrapper {
    height: calc(var(--vh, 1vh) * 100);
  }
}

.isPopup {
  background: rgba(0, 0, 0, 0.3);
  margin: 0;

  .player-wrapper {
    height: 100%;
    width: 100%;
    max-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.format-container,
.carousel-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  transform: translate(0px, 0px);
}

.isCarousel {
  .format-container {
    display: initial;
  }
}

.isHorizontalSwipe {
  .v-slide-wrapper {
    display: grid;
  }
}

.close-button {
  transition: all 0.5s ease-in-out;
}

.hide {
  display: none;
}
</style>
