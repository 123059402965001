var render = function render(){var _vm=this,_c=_vm._self._c;return _c('notifications',{attrs:{"classes":"vidjet-notifications","position":"top left","width":"350px","duration":4000},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('div',{class:[
        'vidjet-notifications',
        {
          error: props.item.type === 'error',
          success: props.item.type === 'success',
        },
      ]},[_c('div',{staticClass:"notification-icon"},[(props.item.type === 'success')?_c('SuccessIcon'):_vm._e(),(props.item.type === 'error')?_c('ErrorIcon'):_vm._e()],1),_c('div',{staticClass:"notification-content"},[_c('h4',{staticClass:"notification-title"},[_vm._v(_vm._s(props.item.title))]),_c('p',{staticClass:"notification-text"},[_vm._v(_vm._s(props.item.text))])])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }